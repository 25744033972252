
.breadcrumb {
	background-color: #4d56e9;
  }
  
  .breadcrumb-style1, .breadcrumb-style2, .breadcrumb-style3 {
	border-radius: 0;
	background-color: transparent;
	padding: 0;
  }
  
  .breadcrumb-style1 .breadcrumb-item, .breadcrumb-style2 .breadcrumb-item, .breadcrumb-style3 .breadcrumb-item {
	font-size: 11px;
	font-weight: 500;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	letter-spacing: .5px;
	text-transform: uppercase;
  }
  
  .breadcrumb-style1 .breadcrumb-item a, .breadcrumb-style2 .breadcrumb-item a, .breadcrumb-style3 .breadcrumb-item a {
	color: $gray-900;
  }
  
  .breadcrumb-style1 .breadcrumb-item a {
	&:hover, &:focus {
	  color: $primary;
	}
  }
  
  .breadcrumb-style2 .breadcrumb-item a {
	&:hover, &:focus {
	  color: $primary;
	}
  }
  
  .breadcrumb-style3 .breadcrumb-item a {
	&:hover, &:focus {
	  color: $primary;
	}
  }
  
  .breadcrumb-style1 .breadcrumb-item.active, .breadcrumb-style2 .breadcrumb-item.active, .breadcrumb-style3 .breadcrumb-item.active {
	color: $primary;
  }
  
  .breadcrumb-style1 .breadcrumb-item + .breadcrumb-item::before, .breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before, .breadcrumb-style3 .breadcrumb-item + .breadcrumb-item::before {
	color: $gray-500;
  }
  
  .breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before, .breadcrumb-style3 .breadcrumb-item + .breadcrumb-item::before {
	width: 16px;
	text-align: center;
	font-family: 'Ionicons';
  }
  
  .breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before {
	content: '\f3d1';
  }
  
  .breadcrumb-style3 .breadcrumb-item + .breadcrumb-item::before {
	content: '\f287';
  }
  
  .main-breadcrumbs {
	padding: 0;
	background-color: transparent;
	margin-bottom: 0;
  
	.breadcrumb-item {
	  font-size: 10px;
	  text-transform: uppercase;
	  letter-spacing: .5px;
	  font-weight: 700;
  
	  a {
		color: $default-color;
		transition: all 0.2s ease-in-out;
  
		&:hover {
		  color: $gray-500;
		}
	  }
  
	  &.active {
		color: $primary;
	  }
  
	  + .breadcrumb-item {
		padding-left: 8px;
  
		&::before {
		  color: $gray-500;
		  padding-right: 8px;
		  font-weight: 400;
		}
	  }
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.main-breadcrumbs .breadcrumb-item a {
	  transition: none;
	}
  }
  
  .breadcrumb-header .content-title {
	color: $white;
  }
  
  .breadcrumb-item {
	a {
	  color: $white;
	}
  
	&.active {
	  color: $white;
	}
  }
  .breadcrumb1 {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    border-radius: 0.25rem;
}
.breadcrumb-item1.active {
    color: #282f53;
}
.breadcrumb-item1+.breadcrumb-item1::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    content: "/";
    color: #c0c9da;
}
.breadcrumb-style2 .breadcrumb-item1+.breadcrumb-item1::before {
    content: "\e92f";
    font-family: feather !important;
    font-size: 12px;
}
.breadcrumb-style3 .breadcrumb-item1+.breadcrumb-item1::before {
    content: "\e933";
    font-family: feather !important;
    font-size: 12px;
}
.breadcrumb-4 ol {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
}
.breadcrumb-4 li, .breadcrumb-5 li {
    display: contents;
    text-align: center;
}
.breadcrumb-4 ol, .breadcrumb-5 ol {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
}

.breadcrumb-3 {
	ol {
	  position: relative;
	  display: block;
	  width: 100%;
	  text-align: center;
	}
  
	li {
	  display: contents;
	  text-align: center;
	}
  }
  .breadcrumb-1 ol, .breadcrumb-2 ol, .breadcrumb-6 ol {
    position: relative;
    display: block;
    width: 100%;
    text-align: right;
}
.breadcrumb-1 li, .breadcrumb-2 li, .breadcrumb-6 li {
    text-align: right;
    display: contents;
}