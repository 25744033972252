
@import "../_variables";
.main-donut-chart {
	position: relative;
	border-radius: 50%;
	overflow: hidden;
  
	.slice {
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	}
  
	.chart-center {
	  position: absolute;
	  border-radius: 50%;
  
	  span {
		display: block;
		text-align: center;
	  }
	}
  }
  
  .chart-legend {
	div {
	  display: flex;
	  align-items: center;
  
	  + div {
		margin-left: 15px;
	  }
	}
  
	span {
	  width: 10px;
	  height: 4px;
	  display: block;
	  margin-right: 5px;
	}
  }
  
  @media (min-width: 576px) {
	.chart-legend div + div {
	  margin-left: 30px;
	}
  }
  
  @media (min-width: 576px) {
	.chart-legend span {
	  width: 40px;
	  margin-right: 10px;
	}
  }
  
  .chart-wrapper {
	position: relative;
	margin-left: -13px;
	margin-right: -13px;
  }
  
  @media (min-width: 576px) {
	.chart-wrapper {
	  margin-left: -10px;
	  margin-right: -15px;
	}
  }
  
  .chart-legend {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 5px;
	left: 0;
	right: 0;
	z-index: 0;
	font-size: 10px;
	font-weight: 500;
	letter-spacing: 1px;
	text-transform: uppercase;
  }
  
  .morris-wrapper-demo {
	height: 200px;
	position: relative;
	margin-left: -15px;
	margin-right: -15px;
  }
  
  @media (min-width: 576px) {
	.morris-wrapper-demo {
	  height: 300px;
	}
  }
  
  .morris-donut-wrapper-demo {
	height: 200px;
  }
  
  @media (min-width: 576px) {
	.morris-donut-wrapper-demo {
	  height: 250px;
	}
  }
  
  .chartjs-wrapper-demo {
	height: 250px;
  }
  
  @media (max-width: 330px) {
	.chartjs-wrapper-demo {
	  width: 290px;
	}
  }
  
  @media (min-width: 992px) {
	.chartjs-wrapper-demo {
	  height: 300px;
	}
  }
  
  .chartist-wrapper-demo {
	height: 200px;
  }
  
  @media (min-width: 768px) {
	.chartist-wrapper-demo {
	  height: 300px;
	}
  }
  
  .morris-hover.morris-default-style {
	border-radius: 0;
  }