
@import "../_variables";
/* ###### 3.8 Input Group ###### */

.input-group-prepend, .input-group-append {
	border-radius: 0;
  }
  
  .input-group-text {
	padding-top: 0;
	padding-bottom: 0;
	align-items: center;
	border-radius: 3px;
  }