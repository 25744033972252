/* ###### 3.9 Modal ###### */

.modal-backdrop {
	background-color: #0c1019;
  
	&.show {
	  opacity: 0.8;
	}
  }
  
  .modal-content {
	border-radius: 0;
	border-width: 0;
  
	.close {
	  font-size: 28px;
	  padding: 0;
	  margin: 0;
	  line-height: 0.5;
	}
  }
  
  .modal-header {
	align-items: center;
	padding: 15px;
  
	.modal-title {
	  margin-bottom: 0;
	}
  }
  
  @media (min-width: 576px) {
	.modal-header {
	  padding: 15px 20px;
	}
  }
  
  @media (min-width: 992px) {
	.modal-header {
	  padding: 20px;
	}
  }
  
  @media (min-width: 1200px) {
	.modal-header {
	  padding: 20px 25px;
	}
  }
  
  .modal-title {
	font-size: 18px;
	font-weight: 700;
	color: $gray-900;
	line-height: 1;
  }
  
  .modal-body {
	padding: 25px;
  }
  
  .modal-open {
	overflow: hidden;
  
	.modal {
	  overflow-x: hidden;
	  overflow-y: auto;
	}
  }
  
  .modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99992;
	display: none;
	width: 100%;
	height: 100%;
	overflow: hidden;
	outline: 0;
  }
  
  .modal-dialog {
	position: relative;
	width: auto;
	margin: 0.5rem;
	pointer-events: none;
  }
  
  .modal {
	&.fade .modal-dialog {
	  transition: transform 0.3s ease-out;
	  transform: translate(0, -50px);
	}
  
	&.show .modal-dialog {
	  transform: none;
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.modal.fade .modal-dialog {
	  transition: none;
	}
  }
  
  .modal-dialog-scrollable {
	display: flex;
	max-height: calc(100% - 1rem);
  
	.modal-content {
	  max-height: calc(100vh - 1rem);
	  overflow: hidden;
	}
  
	.modal-header,
	.modal-footer {
	  flex-shrink: 0;
	}
  
	.modal-body {
	  overflow-y: auto;
	}
  }
  
  .modal-dialog-centered {
	display: flex;
	align-items: center;
	min-height: calc(100% - 1rem);
  
	&::before {
	  display: block;
	  height: calc(100vh - 1rem);
	  content: "";
	}
  
	&.modal-dialog-scrollable {
	  flex-direction: column;
	  justify-content: center;
	  height: 100%;
  
	  .modal-content {
		max-height: none;
	  }
  
	  &::before {
		content: none;
	  }
	}
  }
  
  .modal-content {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: $white;
	background-clip: padding-box;
	border: 0px solid $black-2;
	border-radius: 0.3rem;
	outline: 0;
  }
  
  .modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.89);
  
	&.fade {
	  opacity: 0;
	}
  
	&.show {
	  opacity: 0.9;
	}
  }
  
  .modal-header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 1rem 1rem;
	border-bottom: 1px solid $border;
	border-top-left-radius: 0.3rem;
	border-top-right-radius: 0.3rem;
  
	.close {
	  padding: 1rem 1rem;
	  margin: -1rem -1rem -1rem auto;
	}
  }
  
  .modal-title {
	margin-bottom: 0;
	line-height: 1.5;
  }
  
  .modal-body {
	position: relative;
	flex: 1 1 auto;
	padding: 1rem;
  }
  
  .modal-footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 1rem;
	border-top: 1px solid $border;
	border-bottom-right-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem;
  
	> :not(:first-child) {
	  margin-left: 0.25rem;
	}
  }
  
  #modaldemo8 .modal-footer > :not(:first-child) {
	margin-right: 14px;
  }
  
  .modal-footer > :not(:last-child) {
	margin-right: 0.25rem;
  }
  
  @media (min-width: 576px) {
	.modal-dialog {
	  max-width: 500px;
	  margin: 1.75rem auto;
	}
  
	.modal-dialog-scrollable {
	  max-height: calc(100% - 3.5rem);
  
	  .modal-content {
		max-height: calc(100vh - 3.5rem);
	  }
	}
  
	.modal-dialog-centered {
	  min-height: calc(100% - 3.5rem);
  
	  &::before {
		height: calc(100vh - 3.5rem);
	  }
	}
  
	.modal-sm {
	  max-width: 300px;
	}
  }
  
  @media (min-width: 992px) {
	.modal-lg,
	.modal-xl {
	  max-width: 800px;
	}
  }
  
  @media (min-width: 1200px) {
	.modal-xl {
	  max-width: 1140px;
	}
  }
  
  /* ###### 5.6 Modal   ###### */
  
  .modal {
	&.animated .modal-dialog {
	  transform: translate(0, 0);
	}
  
	&.effect-scale {
	  .modal-dialog {
		transform: scale(0.7);
		opacity: 0;
		transition: all 0.3s;
	  }
  
	  &.show .modal-dialog {
		transform: scale(1);
		opacity: 1;
	  }
	}
  
	&.effect-slide-in-right {
	  .modal-dialog {
		transform: translateX(20%);
		opacity: 0;
		transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
	  }
  
	  &.show .modal-dialog {
		transform: translateX(0);
		opacity: 1;
	  }
	}
  
	&.effect-slide-in-bottom {
	  .modal-dialog {
		transform: translateY(20%);
		opacity: 0;
		transition: all 0.3s;
	  }
  
	  &.show .modal-dialog {
		transform: translateY(0);
		opacity: 1;
	  }
	}
  
	&.effect-newspaper {
	  .modal-dialog {
		transform: scale(0) rotate(720deg);
		opacity: 0;
	  }
  
	  &.show ~ .modal-backdrop,
	  .modal-dialog {
		transition: all 0.5s;
	  }
  
	  &.show .modal-dialog {
		transform: scale(1) rotate(0deg);
		opacity: 1;
	  }
	}
  
	&.effect-fall {
	  -webkit-perspective: 1300px;
	  -moz-perspective: 1300px;
	  perspective: 1300px;
  
	  .modal-dialog {
		-moz-transform-style: preserve-3d;
		transform-style: preserve-3d;
		transform: translateZ(600px) rotateX(20deg);
		opacity: 0;
	  }
  
	  &.show .modal-dialog {
		transition: all 0.3s ease-in;
		transform: translateZ(0px) rotateX(0deg);
		opacity: 1;
	  }
	}
  
	&.effect-flip-horizontal {
	  perspective: 1300px;
  
	  .modal-dialog {
		-moz-transform-style: preserve-3d;
		transform-style: preserve-3d;
		transform: rotateY(-70deg);
		transition: all 0.3s;
		opacity: 0;
	  }
  
	  &.show .modal-dialog {
		transform: rotateY(0deg);
		opacity: 1;
	  }
	}
  
	&.effect-flip-vertical {
	  perspective: 1300px;
  
	  .modal-dialog {
		-moz-transform-style: preserve-3d;
		transform-style: preserve-3d;
		transform: rotateX(-70deg);
		transition: all 0.3s;
		opacity: 0;
	  }
  
	  &.show .modal-dialog {
		transform: rotateX(0deg);
		opacity: 1;
	  }
	}
  
	&.effect-super-scaled {
	  .modal-dialog {
		transform: scale(2);
		opacity: 0;
		transition: all 0.3s;
	  }
  
	  &.show .modal-dialog {
		transform: scale(1);
		opacity: 1;
	  }
	}
  
	&.effect-sign {
	  perspective: 1300px;
  
	  .modal-dialog {
		-moz-transform-style: preserve-3d;
		transform-style: preserve-3d;
		transform: rotateX(-60deg);
		transform-origin: 50% 0;
		opacity: 0;
		transition: all 0.3s;
	  }
  
	  &.show .modal-dialog {
		transform: rotateX(0deg);
		opacity: 1;
	  }
	}
  
	&.effect-rotate-bottom {
	  perspective: 1300px;
  
	  .modal-dialog {
		-moz-transform-style: preserve-3d;
		transform-style: preserve-3d;
		transform: translateY(100%) rotateX(90deg);
		transform-origin: 0 100%;
		opacity: 0;
		transition: all 0.3s ease-out;
	  }
  
	  &.show .modal-dialog {
		transform: translateY(0%) rotateX(0deg);
		opacity: 1;
	  }
	}
  
	&.effect-rotate-left {
	  perspective: 1300px;
  
	  .modal-dialog {
		-moz-transform-style: preserve-3d;
		transform-style: preserve-3d;
		transform: translateZ(100px) translateX(-30%) rotateY(90deg);
		transform-origin: 0 100%;
		opacity: 0;
		transition: all 0.3s;
	  }
  
	  &.show .modal-dialog {
		transform: translateZ(0px) translateX(0%) rotateY(0deg);
		opacity: 1;
	  }
	}
  
	&.effect-just-me {
	  .modal-dialog {
		transform: scale(0.8);
		opacity: 0;
		transition: all 0.3s;
	  }
  
	  .modal-content {
		background-color: $black;
	  }
	}
  }
  
  #modaldemo8 {
	.modal-header {
	  padding: 20px 25px;
	}
	.modal-body {
	  padding: 20px 25px;
	}
	.modal-dialog {
	  box-shadow: none;
	}
  }
  
  .modal.effect-just-me {
	.close {
	  text-shadow: none;
	  color: $white;
	}
  
	.modal-header {
	  background-color: transparent;
	  border-bottom-color: $white-1;
	  padding-left: 0;
	  padding-right: 0;
  
	  h6 {
		color: $white;
		font-weight: 500;
	  }
	}
  
	.modal-body {
	  color: $white-8;
	  padding-left: 0;
	  padding-right: 0;
  
	  h6 {
		color: $white;
	  }
	}
  
	.modal-footer {
	  background-color: transparent;
	  padding-left: 0;
	  padding-right: 0;
	  border-top-color: $white-2;
	}
  
	&.show {
	  ~ .modal-backdrop {
		opacity: 0.96;
	  }
  
	  .modal-dialog {
		transform: scale(1);
		opacity: 1;
	  }
	}
  }
  
  .modal-content-demo .modal-body {
	h6 {
	  color: $gray-900;
	  font-size: 15px;
	  margin-bottom: 15px;
	}
  
	p {
	  margin-bottom: 0;
	}
  }
  .basic-modal .modal-dialog {
	// max-width: inherit;
	margin: 0 auto;
  }
  .basic-modal .modal-content {
	border: 1px solid $border;
  }
  .modal-open {
	.select2-dropdown {
	  z-index: 99999;
	}
  }
  .modal {
	.btn-close {
	  background: none;
	}
  }
  .modal-header .btn-close {
    padding: 0.25em 0.55em !important;
    margin-top: -14px !important;
}