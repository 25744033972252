
@import "../_variables";
/* ###### 3.2 Datepicker ###### */

.ui-datepicker {
	background-color: $white;
	border: 1px solid rgba(28, 39, 60, 0.2);
	font-family: inherit;
	font-size: inherit;
	padding: 10px;
	margin: 1px 0 0;
	display: none;
	width: auto !important;
	z-index: 5 !important;
  
	.ui-datepicker-header {
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	  position: relative;
	  font-weight: 500;
	  font-size: 12px;
	  text-transform: uppercase;
	  color: $default-color;
	  padding: 0 0 5px;
	  letter-spacing: 1px;
	  border: 0;
	  background-color: transparent;
	  border-top-left-radius: 3px;
	  border-top-right-radius: 3px;
  
	  .ui-datepicker-next, .ui-datepicker-prev {
		text-indent: -99999px;
		color: $secondary;
		top: 3px;
	  }
  
	  .ui-datepicker-next::before, .ui-datepicker-prev::before {
		font-size: 16px;
		font-family: 'Ionicons';
		position: absolute;
		top: -2px;
		text-indent: 0;
	  }
  
	  .ui-datepicker-next {
		&:hover::before, &:focus::before {
		  color: $gray-800;
		}
	  }
  
	  .ui-datepicker-prev {
		&:hover::before, &:focus::before {
		  color: $gray-800;
		}
	  }
  
	  .ui-datepicker-next {
		order: 3;
  
		&:before {
		  right: 5px;
		  content: '\f3d1';
		}
	  }
  
	  .ui-datepicker-prev:before {
		left: 5px;
		content: '\f3cf';
	  }
  
	  .ui-datepicker-next-hover, .ui-datepicker-prev-hover {
		color: $gray-700;
		cursor: pointer;
		top: 1px;
		border: 0;
		background-color: transparent;
	  }
	}
  
	.ui-datepicker-title {
	  color: $gray-800;
	  font-weight: 700;
	  font-size: 13px;
	}
  
	.ui-datepicker-calendar {
	  margin: 0;
	  background-color: transparent;
	  border-bottom-right-radius: 3px;
	  border-bottom-left-radius: 3px;
  
	  th {
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 700;
		letter-spacing: 1px;
		padding: 6px 9px;
		color: $gray-800;
	  }
  
	  td {
		border: 1px solid $white;
		padding: 0;
		background-color: $white;
		text-align: right;
  
		&:last-child {
		  border-right: 0;
		}
  
		&.ui-datepicker-other-month .ui-state-default {
		  color: #ccc;
		}
  
		span {
		  transition: all 0.2s ease-in-out;
		  padding: 5px;
		  color: $default-color;
		  padding: 6px 10px;
		  display: block;
		  font-weight: 400;
		  font-size: 12px;
		  border: 0;
		  border-radius: 1px;
		}
  
		a {
		  transition: all 0.2s ease-in-out;
		  padding: 5px;
		  color: $default-color;
		  padding: 6px 10px;
		  display: block;
		  font-weight: 400;
		  font-size: 12px;
		  border: 0;
		  border-radius: 1px;
                  background: $white;
  
		  &:hover {
			background-color: $white;
			color: $default-color;
		  }
		}
	  }
  
	  .ui-datepicker-today a {
		background-color: $primary;
		color: $white;
  
		&:hover, &:focus {
		  background-color: $primary;
		  color: $white;
		}
	  }
	}
  }
  
  @media (max-width: 320px) {
	.ui-datepicker .ui-datepicker-calendar th {
	  padding: 4px 0;
	  letter-spacing: normal;
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.ui-datepicker .ui-datepicker-calendar td {
	  span, a {
		transition: none;
	  }
	}
  }
  
  .ui-datepicker-multi {
	.ui-datepicker-group {
	  padding-right: 15px;
	  width: auto;
	  float: left;
  
	  .ui-datepicker-title {
		margin: auto;
	  }
  
	  .ui-datepicker-prev::before {
		left: 10px;
	  }
  
	  .ui-datepicker-next::before {
		right: 10px;
	  }
  
	  table {
		margin: 0;
	  }
	}
  
	.ui-datepicker-group-last {
	  padding-right: 0;
	}
  }
  
  .ui-datepicker-inline {
	border-color: $gray-200;
  }
  
  @media (min-width: 576px) {
	.ui-datepicker-inline {
	  max-width: 277px;
	}
  }
  
  .main-datepicker {
	.ui-datepicker-inline {
	  margin-top: 0;
	}
  
	.ui-datepicker {
	  .ui-datepicker-title {
		color: $primary;
		letter-spacing: -.2px;
	  }
  
	  .ui-datepicker-calendar {
		width: 100%;
  
		th {
		  padding: 5px;
		  font-size: 10px;
		  color: $gray-900;
		  text-align: center;
		}
  
		td {
		  text-align: center;
		}
  
		th.ui-datepicker-week-end:first-child:not(.ui-datepicker-other-month) a {
		  color: $danger;
		}
  
		td {
		  &.ui-datepicker-week-end:first-child:not(.ui-datepicker-other-month) a {
			color: $danger;
		  }
  
		  padding: 0;
  
		  span, a {
			padding: 4px 5px;
			font-family: Verdana, sans-serif;
			font-size: 11px;
			color: $gray-700;
		  }
		}
  
		.ui-datepicker-today a {
		  background-color: $primary;
		  color: $white;
		  border-radius: 2px;
		}
	  }
	}
  }
  
  .card-dashboard-calendar {
	.ui-datepicker-inline {
	  border-width: 0;
	  max-width: none;
	  padding: 0;
	  margin: 0;
	}
  
	.ui-datepicker {
	  .ui-datepicker-header {
		border-bottom: 1px solid $gray-200;
		padding: 10px;
		margin-bottom: 5px;
  
		.ui-datepicker-next::before, .ui-datepicker-prev::before {
		  top: 8px;
		}
	  }
  
	  .ui-datepicker-month {
		color: $primary;
	  }
  
	  .ui-datepicker-calendar {
		width: calc(100% - 20px);
  
		th {
		  font-size: 10px;
		  text-align: center;
		}
  
		td {
		  text-align: center;
  
		  a {
			display: block;
			width: 34px;
			height: 34px;
			font-size: 12px;
			font-family: 'Archivo', sans-serif;
			border-radius: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		  }
		}
  
		.ui-datepicker-today a {
		  background-color: transparent;
		  border: 2px solid $primary;
		  color: $primary;
		  font-weight: 700;
		}
	  }
	}
  }
  
  @media (min-width: 576px) {
	.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar {
	  width: 100%;
	}
  }