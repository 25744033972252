@import "../scss/variables";

body.dark-theme {
  background: $dark-body!important;
}

.dark-theme {
  .br-dark {
    border-left-color: $dark-border !important;
    border-right-color: $dark-border !important;
  }

  .badge.bg-light {
    color: $dark-color;
  }

  .card-signin h1 {
    color: $dark-color !important;
  }

  .bg-white {
    background-color: $dark-theme !important;
  }

  .card {
    background: $dark-theme !important;
    border-color: $dark-border;
    box-shadow: 0 0 10px $dark-shadow;
  }

  .main-header {
    background:linear-gradient(45deg, #f33057, $primary);;
    border-bottom-color: $dark-border;
    box-shadow: 0 0 10px $dark-shadow;
  }

  .main-header-message > a,
  .main-header-notification > a,
  .nav-item.full-screen > a,
  .card-title {
    color: $white;
  }

  .card-dashboard-audience-metrics {
    .card-header,
    .card-body {
      background-color: $dark-theme;
    }
  }

  .card-header {
    background-color: $dark-theme;
  }

  .border-bottom {
    border-bottom-color: $dark-border !important;
  }

  .border-top {
    border-top-color: $dark-border !important;
  }

  .border-right {
    border-right-color: $dark-border !important;
    border-left-color: $dark-border !important;
  }

  .border-left {
    border-left-color: $dark-border !important;
    border-right-color: $dark-border !important;
  }

  .border {
    border-color: $dark-border !important;
  }

  .table tbody tr {
    background-color: $dark-theme;
  }

  .rating-table tbody tr {
    background-color: transparent;
  }

  .table-bordered thead {
    th,
    td {
      background-color: $dark-theme;
    }
  }

  .table {
    color: $dark-color;
  }

  .table-bordered {
    border-color: $dark-border !important;

    th,
    td {
      border-color: $dark-border;
    }
  }

  .card-dashboard-audience-metrics .card-body h4 {
    color: $dark-color;
  }

  .progress {
    background-color: $dark-theme2;
  }

  .card-dashboard-audience-metrics .flot-chart .flot-x-axis > div span:last-child {
    color: $dark-color;
  }

  .main-footer {
    background-color: $dark-theme;
    border-top-color: $dark-border;
  }

  .sidebar {
    &.sidebar-right {
      box-shadow: 5px 7px 26px -5px $dark-shadow !important;
    }

    .tabs-menu ul {
      border-bottom-color: $dark-border;
    }
  }

  .chat .contacts li {
    border-bottom-color: $dark-border;
  }

  .form-control {
    color: $dark-color;
    background-color: $dark-theme;
    border-color: $dark-border !important;
  }

  .list-group-item {
    background-color: $dark-theme;
    border-color: $dark-border;
    color: $dark-color !important;
  }

  .main-header-center .form-control {
    border-color: $dark-border !important;
    background-color: $dark-theme2 !important;
  }

  .main-header {
    form[role="search"].active input {
      background: $dark-theme;
      color: $dark-color;
      border-bottom-color: $dark-border !important;
      border-top-color: $dark-border !important;
    }

    .input-group-btn .btn:hover i {
      color: $dark-color;
    }
  }

  .main-header-notification .dropdown-menu {
    background-color: $dark-theme;
  }

  .notification-label {
    color: $dark-color;
  }

  .main-notification-list a:hover,
  .main-message-list a:hover {
    background: $dark-hover;
  }

  .nav .nav-item .dropdown-menu {
    -webkit-box-shadow: 0px 0px 15px 1px $dark-shadow;
    box-shadow: 0px 0px 15px 1px $dark-shadow;
  }

  .notification-subtext {
    color: $dark-color;
  }

  .main-header-message .dropdown-footer,
  .main-header-notification .dropdown-footer {
    border-top-color: $dark-border;
    background: $dark-theme2;
  }

  .dropdown-menu {
    color: $dark-color;
    background-color: $dark-theme;
    border-color: $dark-border;
  }

  .main-message-list a {
    .name,
    .time,
    .desc {
      color: $dark-color;
    }
  }

  .task-line a,
  .latest-tasks .nav-tabs .nav-link,
  .chips p {
    color: $dark-color;
  }

  .chip {
    color: $dark-color;
    background-color: $dark-theme2;

    &:hover {
      color: $dark-color;
    }
  }

  .latest-tasks .check-box .ckbox span:before {
    border-color: $dark-border;
  }

  .table {
    th,
    td {
      border-top-color: $dark-border;
    }
  }

  #global-loader {
    background: $dark-theme2;
  }

  .app-sidebar {
    background: $dark-theme;
    box-shadow: 0 0 10px $dark-shadow;
    border-right-color: $dark-border;
    border-left-color: $dark-border;
  }

  .main-sidebar-header {
    background: $dark-theme;
  }

  .side-menu__label,
  .side-menu .side-menu__icon {
    color: $dark-color;
    fill: $dark-color;
  }

  .app-sidebar__user .user-info h4,
  .side-menu h3 {
    color: $dark-color !important;
  }

  .main-sidebar-header {
    border-bottom-color: $dark-border ;
    border-right-color: $dark-border !important;
    border-left-color: $dark-border !important;
  }

  .main-sidebar-loggedin .media-body h6,
  .slide.is-expanded a,
  .side-menu__item {
    color: $dark-color;
  }

  .slide.is-expanded:before {
    background: $dark-theme2;
  }

  .slide-item {
    color: $dark-color;
  }

  .slide-menu .slide-item:before {
    color: $dark-color;
  }

  .open-toggle svg g,
  .close-toggle svg g {
    fill: $white;
  }

  .angle,
  .sub-angle {
    color: $dark-color !important;
  }

  .main-header-center {
    .btn:hover,
    .sp-container button:hover {
      color: $dark-color;
    }
  }

  .sp-container .main-header-center button:hover {
    color: $dark-color;
  }

  .main-header-center {
    .btn:focus,
    .sp-container button:focus {
      color: $dark-color;
    }
  }

  .sp-container .main-header-center button:focus,
  .main-header .input-group-btn .btn i {
    color: $dark-color;
  }

  .main-mail-item {
    border-top-color: $dark-border;
    border-bottom-color: $dark-border;

    &.unread {
      background-color: $dark-theme;
    }
  }

  .main-content-title {
    color: $dark-color;
  }

  .main-mail-options,
  .main-mail-list {
    border-color: $dark-border;
  }

  .main-mail-item {
    background-color: $dark-hover;
  }

  .main-mail-subject strong {
    color: $dark-color;
  }

  .ckbox span:before {
    background-color: $dark-theme2;
    border-color: $dark-border;
  }

  .main-mail-star {
    color: $dark-color;
  }

  .main-nav-column .nav-link {
    color: $dark-color;

    &:hover,
    &:focus {
      color: $dark-color;
    }
  }

  .btn-light {
    color: $dark-color;
    background-color: $dark-theme2;
    border-color: $dark-border;
  }

  .main-nav-column .nav-link {
    &:hover i:not([class*=" tx-"]),
    &:focus i:not([class*=" tx-"]) {
      color: $dark-color;
    }

    + .nav-link {
      border-top-color: $dark-border;
    }
  }
  .side-menu .slide {
    border-bottom-color: $dark-border;
 }
  .nav-search .input-group-text {
    color: $dark-color;
    background-color: $dark-color;
    border-color: $dark-border;
  }

  .main-mail-header .btn-group {
    .btn,
    .sp-container button {
      border-color: $dark-border;
      background-color: $dark-theme;
    }
  }

  .sp-container .main-mail-header .btn-group button {
    border-color: $dark-border;
    background-color: $dark-theme;
  }

  .main-mail-header .btn-group {
    .btn.disabled,
    .sp-container button.disabled {
      background-color: $dark-theme;
      color: $dark-color;
      border-color: $dark-border;
    }
  }

  .sp-container .main-mail-header .btn-group button.disabled {
    background-color: $dark-theme;
    color: $dark-color;
    border-color: $dark-border;
  }

  .main-mail-header .btn-group {
    .btn:hover,
    .sp-container button:hover {
      color: $dark-color;
      background-color: $dark-color;
    }
  }

  .sp-container .main-mail-header .btn-group button:hover {
    color: $dark-color;
    background-color: $dark-color;
  }

  .main-mail-header .btn-group {
    .btn:focus,
    .sp-container button:focus {
      color: $dark-color;
      background-color: $dark-color;
    }
  }

  .sp-container .main-mail-header .btn-group button:focus {
    color: $dark-color;
    background-color: $dark-color;
  }

  .card-header,
  .card-footer,
  hr {
    border-color: $dark-border;
  }

  .main-content-label,
  .card-table-two .card-title,
  .card-dashboard-eight .card-title,
  .form-label {
    color: $white;
  }

  .select2-container--default .select2-selection--single {
    background-color: $dark-theme2;
    border-color: $dark-border;

    .select2-selection__rendered {
      color: $dark-color;
    }
  }

  .select2-dropdown {
    background-color: $dark-theme;
    border-color: $dark-color;
  }

  .select2-container--default {
    .select2-results__option[aria-selected="true"] {
      background-color: $dark-theme2;
    }

    .select2-search--dropdown .select2-search__field {
      border-color: $dark-border;
      background: $dark-theme;
      color: $dark-color;
    }
  }

  .main-nav-line-chat {
    border-bottom-color: $dark-border !important;
  }

  .main-nav-line .nav-link,
  .main-chat-msg-name h6 {
    color: $dark-color;
  }

  .main-chat-header {
    border-bottom-color: $dark-border;
    box-shadow: 2px 3px 14px $dark-shadow;
  }

  .main-chat-list {
    .media {
      border-bottom-color: $dark-border;

      &.new {
        background-color: $dark-theme;

        .media-body p,
        .media-contact-name span:first-child {
          color: $dark-color;
        }
      }
    }

    .media-contact-name span:first-child {
      color: $white !important;
    }

    .media.selected {
      background-color: $dark-hover;
    }
  }

  .main-chat-contacts-wrapper {
    border-bottom-color: $dark-border;
  }

  .main-chat-list .media {
    &:hover,
    &:focus {
      background-color: $dark-hover;
    }

    &.selected .media-body p {
      color: $dark-color;
    }
  }

  .main-msg-wrapper {
    background-color: $dark-theme2;
    color: $dark-color;
  }

  .main-chat-footer {
    border-top-color: $dark-border;
    background-color: $dark-theme2;
  }

  .irs-line-mid,
  .irs-line-left,
  .irs-line-right {
    background-color: $dark-theme2;
  }

  .irs-min,
  .irs-max {
    color: $dark-color;
    background: $dark-theme2;
  }

  .main-calendar .fc-header-toolbar h2 {
    color: $dark-color;
  }

  .ui-datepicker {
    background-color: $dark-theme;
    box-shadow: 0 0 24px $dark-shadow;
    border-color: $dark-border;

    .ui-datepicker-calendar td {
      border-color: $dark-border;
      background-color: $dark-theme2;

      span,
      a {
        color: $dark-color;
      }
    }

    .ui-datepicker-title,
    .ui-datepicker-calendar th {
      color: $white;
    }
  }

  .main-datepicker .ui-datepicker .ui-datepicker-calendar th {
    color: $dark-color;
  }

  .card--calendar .ui-datepicker .ui-datepicker-header {
    border-bottom-color: $dark-border;
  }

  .ui-datepicker .ui-datepicker-calendar td a:hover {
    color: $dark-color;
  }

  .main-calendar {
    .fc-view > table {
      background-color: $dark-theme;
    }

    .fc-head-container .fc-day-header {
      color: $dark-color;
    }

    .fc-view {
      .fc-day-number {
        color: $dark-color;
      }

      .fc-other-month {
        background-color: $dark-theme2;
      }
    }

    .fc-content,
    .main-calendar .fc-divider,
    .fc-list-heading td,
    .fc-list-view,
    .fc-popover,
    .fc-row,
    tbody,
    td,
    .main-calendar th,
    thead {
      border-color: $dark-border;
    }

    .fc-view {
      .fc-day-number {
        &:hover,
        &:focus {
          color: $dark-color;
        }
      }

      > table > {
        thead {
          th,
          td {
            border-color: $dark-color;
          }
        }

        tbody > tr > td {
          border-color: $dark-border;
        }
      }
    }

    .fc-header-toolbar button {
      background-color: $dark-theme2;
      border-color: $dark-border;
    }

    .fc-view {
      &.fc-listMonth-view .fc-list-item,
      &.fc-listWeek-view .fc-list-item {
        background-color: $dark-theme2;
      }
    }
  }

  .fc-unthemed {
    .fc-divider,
    .fc-list-heading td,
    .fc-popover .fc-header {
      background: $dark-theme2;
    }
  }

  .main-calendar .fc-view {
    &.fc-listMonth-view .fc-list-item-title .fc-desc,
    &.fc-listWeek-view .fc-list-item-title .fc-desc,
    &.fc-listMonth-view .fc-list-item-title a,
    &.fc-listWeek-view .fc-list-item-title a,
    &.fc-listMonth-view .fc-list-heading-main span:last-child,
    &.fc-listWeek-view .fc-list-heading-main span:last-child {
      color: $dark-color;
    }
  }

  .main-contact-info-header .media-body p,
  .tx-inverse {
    color: $dark-color;
  }

  .contact-icon:hover {
    background: $dark-theme2;
    color: $dark-color;
  }

  .main-contact-info-header {
    border-bottom-color: $dark-border;
  }

  .main-contact-info-body {
    .media-body {
      span,
      label {
        color: $dark-color;
      }
    }

    .media + .media::before {
      border-top-color: $dark-border;
    }
  }

  .main-contact-body {
    span,
    h6 {
      color: $dark-color;
    }
  }

  .main-contact-item {
    &.selected {
      border-top-color: $dark-border !important;
      border-bottom-color: $dark-border !important;
      background-color: $dark-theme2;
    }

    + .main-contact-item {
      border-top-color: $dark-border;

      &::before {
        border-top-color: $dark-border;
      }
    }

    &:hover,
    &:focus {
      background-color: $dark-theme2;
      border-top-color: $dark-border;
      border-bottom-color: $dark-border;
    }
  }

  .main-contact-label::after {
    border-bottom-color: $dark-border;
  }

  #ui_notifIt.default {
    background-color: $dark-theme;
    border-color: $dark-border;
  }

  .notifit_confirm,
  .notifit_prompt {
    background-color: $dark-theme;
  }

  .alert .close {
    color: $dark-color;
    opacity: 1;
  }

  .tree {
    li {
      color: $dark-color;

      a {
        color: $dark-color;
      }
    }

    ul {
      li:last-child:before {
        background: $dark-theme;
      }

      &:before {
        border-left-color: $dark-border !important;
        border-right-color: $dark-border !important;
      }

      li:before {
        border-top-color: $dark-border;
      }
    }
  }

  .text-muted {
    color: $dark-color !important;
  }

  .main-icon-group {
    color: $dark-color;
  }

  .table thead th {
    border-bottom-color: $dark-border;
    color: $dark-color;
  }

  .table-hover tbody tr:hover {
    color: $dark-color;
    background-color: $dark-hover !important;
  }
  .task-stat .tasks {
    color: $white;
    border-bottom-color:$dark-border;
  }
  .list-group.projects-list .list-group-item-action:hover, .list-group.projects-list .list-group-item-action:focus{
    background-color: $dark-hover;
  }

  table.dataTable {
    border-color: $dark-border;

    tbody td.sorting_1 {
      background-color: $dark-theme;
    }

    thead {
      th,
      td {
        color: $dark-color;
      }

      .sorting_asc,
      .sorting_desc {
        background-color: $dark-theme2;
      }
    }
  }

  .dataTables_wrapper {
    .dataTables_length,
    .dataTables_filter,
    .dataTables_info,
    .dataTables_processing,
    .dataTables_paginate {
      color: $dark-color;
    }

    .dataTables_filter input {
      border-color: $dark-border;
    }
  }

  .page-link {
    color: $dark-color;
    background-color: $dark-theme2;
  }

  .dataTables_wrapper .dataTables_paginate .page-item.disabled .page-link {
    background-color: $dark-theme2;
    color: $dark-color;
  }

  select option {
    background: $dark-theme;
  }

  table.dataTable tbody tr.selected {
    background: $dark-theme2;
  }

  .example {
    border-color: $dark-border;
  }

  #basic-alert .alert .close,
  #dismiss-alerts .alert .close {
    color: $white;
  }

  #icon-dismissalerts {
    .alert .close,
    .alert-default.alert-dismissible .close {
      color: $dark-color;
    }
  }

  .main-table-reference > {
    thead > tr > {
      th,
      td {
        border-color: $dark-border;
      }
    }

    tbody > tr > {
      th,
      td {
        border-color: $dark-border;
      }
    }
  }

  pre {
    color: $dark-color;
    background-color: $dark-theme2;
  }

  .token {
    &.operator,
    &.entity,
    &.url {
      background: $dark-theme2;
    }
  }

  .language-css .token.string,
  &.style .token.string {
    background: $dark-theme2;
  }

  .highlight {
    border-color: $dark-border !important;
    background: $dark-theme2;
  }

  .clipboard-icon {
    background: $dark-theme2;
    border-color: $dark-border;
  }

  .main-table-reference > thead > tr > {
    th,
    td {
      background: $dark-theme2 !important;
      border-color: $dark-border !important;
    }
  }

  .breadcrumb-style1 .breadcrumb-item a,
  .breadcrumb-style2 .breadcrumb-item a,
  .breadcrumb-style3 .breadcrumb-item a {
    color: $dark-color;
  }

  .dropdown-item {
    color: $dark-color;

    &:hover,
    &:focus {
      background: $dark-theme;
    }
  }

  .dropdown-divider {
    border-top-color: $dark-border;
  }

  .img-thumbnail {
    background-color: $dark-theme2;
    border-color: $dark-border;

    p {
      color: $dark-color;
    }
  }

  .bd {
    border-color: $dark-border;
  }

  .bg-light {
    background-color: $dark-theme2 !important;
  }

  .main-nav .nav-link {
    &:hover,
    &:focus {
      color: $dark-color;
    }
  }

  .nav-pills .nav-link {
    color: $dark-color;

    &:hover,
    &:focus {
      color: $dark-color;
    }

    &.active {
      color: $dark-color !important;
    }
  }

  .main-nav .nav-link {
    color: $dark-color;
  }

  #tab .bg-gray-300 {
    background-color: $dark-theme2;
    border-bottom-color: $dark-border;
  }

  .nav-tabs .nav-link {
    color: $dark-color;

    &.active,
    &:hover,
    &:focus {
      color: $dark-color;
    }
  }

  .popover-static-demo {
    background-color: $dark-theme2;
  }

  .popover {
    background-color: $dark-theme;
    border-color: $dark-border;
  }

  .popover-body {
    color: $dark-color;
  }

  .popover-header {
    color: $dark-color;
    background-color: transparent;
    border-color: $dark-border;
  }

  .bs-popover-top > .popover-arrow::before,
  .bs-popover-auto[x-placement^="top"] > .popover-arrow::before,
  .bs-popover-top > .popover-arrow::after,
  .bs-popover-auto[x-placement^="top"] > .popover-arrow::after {
    border-top-color: $dark-border;
  }

  .bs-popover-bottom > .popover-arrow::after,
  .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::after,
  .bs-popover-bottom > .popover-arrow::before,
  .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::before {
    border-bottom-color: $dark-border;
  }

  .bs-popover-start > .popover-arrow::after,
  .bs-popover-auto[x-placement^="left"] > .popover-arrow::after,
  .bs-popover-start > .popover-arrow::after,
  .bs-popover-auto[x-placement^="left"] > .popover-arrow::after {
    border-left-color: $dark-theme important;
  }

  .bs-popover-end > .popover-arrow::after,
  .bs-popover-auto[x-placement^="right"] > .popover-arrow::after,
  .bs-popover-end > .popover-arrow::before,
  .bs-popover-auto[x-placement^="right"] > .popover-arrow::before {
    border-right-color: $dark-theme !important;
  }

  .bs-popover-start > .popover-arrow::before,
  .bs-popover-auto[x-placement^="left"] > .popover-arrow::before {
    border-left-color: $dark-border;
    border-right-color: $dark-border;
  }
  &.rtl .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after, &.rtl .bs-popover-start>.popover-arrow::after{
    border-right-color: $dark-theme !important;
    border-left-color: $dark-theme !important;
  }

  .media-body {
    color: $dark-color;
  }

  .tooltip-static-demo {
    background-color: $dark-theme2 !important;
  }

  .toast-header {
    border-bottom-color: $dark-border;
  }

  .toast {
    background-color: $dark-theme;
    border-color: $dark-border;
  }

  .toast-header {
    color: $dark-color;
    background: $dark-theme2;
  }

  .bootstrap-tagsinput {
    background-color: $dark-theme2;
  }

  .tag {
    color: $dark-color;
    background-color: $dark-theme2;
  }

  .tag.tag-attachments {
    background-color: transparent;
  }

  .tag.tag-attachments-lg {
    background-color: transparent;
  }

  .tag.tag-attachments-sm {
    background-color: transparent;
  }

  .accordion {
    .card-header a {
      color: $dark-color;
      background-color: $dark-theme2;
    }

    .card-body {
      background-color: $dark-theme2;
    }

    .card-header a.collapsed {
      &:hover,
      &:focus {
        color: $dark-color;
      }
    }
  }

  .modal-content {
    background-color: $dark-theme;
    border-color: $dark-border;
  }

  .modal-header {
    border-bottom-color: $dark-border;
  }

  .modal-title {
    color: $dark-color;
  }

  .modal-footer {
    border-top-color: $dark-border;
  }

  .close {
    color: $dark-color;
    text-shadow: 0 1px 0 $dark-color;
  }

  .modal-content-demo .modal-body h6 {
    color: $dark-color;
  }

  .vtimeline {
    .timeline-wrapper {
      .timeline-panel {
        background: $dark-theme2;
        box-shadow: 0 8px 16px 0 $dark-shadow;
      }

      &.timeline-inverted .timeline-panel:after {
        border-right-color: $dark-theme2 !important;
        border-left-color: $dark-theme2 !important;
      }

      .timeline-panel:after {
        border-right-color: $dark-theme2 !important;
        border-left-color: $dark-theme2 !important;
      }
    }

    &:before {
      background-color: $dark-theme2;
    }
  }

  .timeline-body {
    color: $dark-color;
  }

  .sweet-alert {
    background-color: $dark-theme;

    h2 {
      color: $dark-color;
    }
  }

  .btn-outline-light {
    border-color: $dark-border !important;
    color: $dark-color;

    &:hover,
    &:focus {
      background-color: $dark-hover !important;
      border-color: $dark-border !important;
      color: $dark-color !important;
    }
  }

  .dropdown .fe-more-vertical {
    color: $dark-color;
  }

  .main-content-body-profile .nav {
    border-bottom-color: $dark-border;
  }

  .card-body + .card-body {
    border-top-color: $dark-border;
  }

  .rating-stars {
    input {
      color: $dark-color;
      background-color: $dark-theme2;
      border-color: $dark-border;
    }

    .rating-stars-container .rating-star {
      color: $dark-color;
    }
  }

  .br-theme-bars-pill .br-widget a {
    &.br-active,
    &.br-selected {
      color: $dark-color;
    }
  }

  .br-theme-bars-1to10 .br-widget a,
  .br-theme-bars-movie .br-widget a,
  .br-theme-bars-horizontal .br-widget a {
    background-color: $dark-color;
  }

  .br-theme-bars-square .br-widget a {
    border-color: $dark-border;
    background-color: $dark-theme2;
    color: $dark-color;
  }

  .br-theme-bars-pill .br-widget a {
    background-color: $dark-theme2;
  }

  .custom-file-label {
    color: $dark-color;
    background-color: $dark-theme2;
    border-color: $dark-border;

    &::after {
      color: $dark-color;
    }
  }

  .input-group-text {
    color: $dark-color;
    background-color: $dark-theme2;
    border-color: $dark-border;
  }

  .sp-replacer {
    border-color: $dark-border;
    background-color: $dark-theme2;

    &:hover,
    &:focus {
      border-color: $dark-border;
    }
  }

  .sp-container {
    background-color: $dark-theme;
    border-color: $dark-border;
  }

  .select2-container--default {
    &.select2-container--focus .select2-selection--multiple {
      border-color: $dark-color;
      background: $dark-theme2;
    }

    .select2-selection--multiple {
      background-color: $dark-theme2 !important;
      color: $dark-color;
      border-color: $dark-border;
    }

    &.select2-container--disabled .select2-selection--multiple {
      background-color: $dark-theme2 !important;
    }
  }
  .custom-control-input:disabled~.custom-control-label{
    color: $white-3;
  }

  .SumoSelect {
    > {
      .CaptionCont {
        border-color: $dark-border;
        color: $dark-color;
        background-color: $dark-theme2;
      }

      .optWrapper {
        background: $dark-theme;
        border-color: $dark-border;
        box-shadow: 0 2px 17px 2px $dark-shadow;
      }
    }

    .select-all {
      border-bottom-color: $dark-border;
      background-color: $dark-theme;
    }

    > .optWrapper > {
      .options li.opt {
        border-bottom-color: $dark-border;
      }

      .MultiControls {
        border-top-color: $dark-border;
        background-color: $dark-theme;
      }
    }

    &.open > .optWrapper {
      box-shadow: 0 2px 17px 2px $dark-shadow;
      border-color: $dark-border;
    }

    &.disabled > .CaptionCont {
      border-color: $dark-border;
    }

    > .optWrapper {
      > .options li.opt:hover {
        background-color: $dark-theme2;
      }

      &.multiple > .options li.opt span i {
        border-color: $dark-border !important;
        background-color: $dark-theme2;
      }
    }

    .select-all > span i {
      border-color: $dark-border;
      background-color: $dark-theme2;
    }
  }
  .SumoSelect .select-all.partial > span i {
    background-color: $dark-theme2;
  }
  .SumoSelect > .optWrapper.multiple > .MultiControls > p.btnOk {
    border-right-color: $dark-border;
    border-left-color: $dark-border;
  }
  .SumoSelect:focus > .CaptionCont {
    border-color: $dark-border;
  }

  .dropify-wrapper {
    background-color: $dark-theme2;
    border-color: $dark-border;
    color: $dark-color;

    .dropify-preview {
      background-color: $dark-color;
    }
  }

  .ff_fileupload_wrap .ff_fileupload_dropzone {
    border-color: $dark-border;
    background-color: $dark-theme2;
    background-image: url(../plugins/fancyuploder/fancy_upload-dark.png);

    &:hover,
    &:focus,
    &:active {
      background-color: $dark-theme;
      border-color: $dark-color;
    }
  }

  .main-form-group {
    border-color: $dark-border;
  }

  .parsley-style-1 {
    .parsley-input.parsley-error .form-control,
    .parsley-checkbox.parsley-error,
    .parsley-select.parsley-error .select2-container--default .select2-selection--single {
      background-color: $dark-theme2;
    }
  }

  .wizard {
    border-color: $dark-border;
    background-color: $dark-theme2;

    > {
      .steps a {
        .number,
        &:hover .number,
        &:active .number {
          background-color: $dark-theme;
        }
      }

      .content {
        border-top-color: $dark-border;
        border-bottom-color: $dark-border;

        > .title {
          color: $dark-color;
        }
      }
    }
  }

  .ql-scrolling-demo {
    border-color: $dark-border;
    background: $dark-theme2;

    .ql-container .ql-editor {
      color: $dark-color;
    }
  }

  .ql-snow {
    .ql-picker-label {
      border-color: $dark-border;
      background-color: $dark-theme2;
    }

    .ql-stroke {
      stroke: $dark-color;
    }

    .ql-editor,
    &.ql-toolbar button,
    .ql-picker {
      color: $dark-color;
    }

    &.ql-toolbar,
    &.ql-container {
      border-color: $dark-border;
    }

    .ql-picker-options {
      background-color: $dark-theme;
    }
  }

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: $dark-border;
  }

  .main-profile-work-list .media-body {
    h6,
    p {
      color: $dark-color;
    }
  }

  .main-profile-contact-list .media-body {
    div,
    span {
      color: $dark-color;
    }
  }

  .plan-icon {
    border-color: $dark-border;
    background: $dark-theme2;
  }

  .main-profile-name,
  .main-profile-body .media-body h6,
  .main-profile-social-list .media-body a {
    color: $dark-color;
  }

  .profile-footer a {
    background: $dark-theme2;
    color: $dark-color;
  }

  .billed-from h6,
  .invoice-title {
    color: $dark-color;
  }

  .main-invoice-list {
    .media-body h6 {
      color: $dark-color;
    }

    .selected {
      background-color: $dark-theme2;
      border-top-color: $dark-border;
      border-bottom-color: $dark-border;
    }

    .media {
      border-color: $dark-border;

      &:hover,
      &:focus {
        background-color: $dark-theme2;
      }
    }

    .media-body p {
      color: $dark-color;

      span {
        color: $dark-color;
      }
    }
  }

  .table-invoice tbody > tr > {
    th:first-child .invoice-notes p,
    td:first-child .invoice-notes p,
    th:first-child,
    td:first-child {
      color: $dark-color;
    }
  }

  .billed-from p,
  .billed-to p,
  .card-invoice .tx-gray-600,
  .billed-to h6 {
    color: $dark-color;
  }

  .invoice-info-row {
    + .invoice-info-row {
      border-top-color: $dark-border;
    }

    span:first-child {
      color: $dark-color;
    }
  }

  .main-invoice-list {
    border-top-color: $dark-border;
  }

  .card-category {
    background: $dark-theme2;
  }

  .pricing-card .list-unstyled li {
    border-bottom-color: $dark-border;
  }

  .price {
    &.panel-color > .panel-body,
    .panel-footer {
      background-color: $dark-theme;
      border-top-color: $dark-border;
    }
  }

  .pricing .list-unstyled li {
    border-bottom-color: $dark-border;
  }

  .card--events .list-group-item h6 {
    color: $dark-color;
  }

  .rdiobox span:before {
    background-color: $dark-theme2;
    border-color: $dark-border;
  }

  .colorinput-color {
    border-color: $dark-border;
  }

  .nice-select {
    .list {
      background-color: $dark-theme2;
      -webkit-box-shadow: 0px 0px 15px 1px $dark-shadow;
      box-shadow: 0px 0px 15px 1px $dark-shadow;
    }

    .option {
      &:hover,
      &.focus,
      &.selected.focus {
        background-color: $dark-theme;
      }
    }
  }

  .item-card {
    .cardtitle a,
    .cardprice span {
      color: $dark-color;
    }
  }

  .bd-b {
    border-bottom-color: $dark-border;
  }

  .bd-r {
    border-right-color: $dark-border;
    border-left-color: $dark-border;
  }

  .bd-t {
    border-top-color: $dark-border;
  }

  .bd-l {
    border-left-color: $dark-border;
    border-right-color: $dark-border;
  }

  .bd-y {
    border-top-color: $dark-border;
    border-bottom-color: $dark-border;
  }

  .bd-x {
    border-left-color: $dark-border;
    border-right-color: $dark-border;
  }

  .main-card-signin {
    border-color: $dark-border;
  }

  .main-signin-header h4,
  .main-signin-footer a,
  .main-signup-footer a {
    color: $dark-color;
  }

  .main-error-wrapper {
    h1,
    h2,
    h6 {
      color: $white;
    }
  }

  .construction .btn.btn-icon {
    border-color: $dark-border;
  }

  .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: $dark-theme2 !important;
  }

  .sidebar {
    background: $dark-theme;
  }

  .main-calendar.fc-list-empty {
    background-color: $dark-theme;
    border-color: $dark-border;
  }
}

@media (min-width: 1245px) {
  .dark-theme .main-header-center .form-control {
    border-color: $dark-border !important;
    background-color: $dark-theme !important;
  }
}

@media (max-width: 991px) {
  .dark-theme .vtimeline .timeline-wrapper .timeline-panel:after {
    border-right-color: $dark-border !important;
    border-left-color: $dark-border !important;
  }
}

@media (min-width: 576px) {
  .dark-theme .wizard.vertical > {
    .content,
    .actions {
      border-left-color: $dark-border;
      border-right-color: $dark-border;
    }
  }
}

@media (max-width: 991px) {
  .dark-theme .animated-arrow span {
    background: $dark-theme2;

    &:before,
    &:after {
      background: $dark-theme2;
    }
  }
}

@media (max-width: 575px) {
  .dark-theme .main-calendar .fc-header-toolbar button {
    &.fc-month-button::before,
    &.fc-agendaWeek-button::before,
    &.fc-agendaDay-button::before,
    &.fc-listWeek-button::before,
    &.fc-listMonth-button::before {
      color: $dark-color;
    }
  }
}

.dark-them {
  .breadcrumb {
    background-color: $dark-theme2;
  }

  table.dataTable > tbody > tr.child ul.dtr-details > li {
    border-bottom-color: $dark-border;
  }
}

.dark-theme {
  .horizontal-main.hor-menu {
    background: $dark-theme;
    border-bottom-color: $dark-border;
  }

  .horizontalMenu > .horizontalMenu-list > li > a {
    color: $dark-color;
  }

  .horizontalMenucontainer .main-header {
    border-bottom-color: $dark-border;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
    border-color: $dark-border;

    > li > a {
      color: $dark-color;
    }
  }

  .sub-menu li a:before {
    border-color: $dark-border;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
    .sub-menu-sub:after {
      color: $dark-color;
    }

    > li > ul.sub-menu {
      background-color: $dark-theme;
      box-shadow: 0 8px 16px 0 $dark-shadow;

      > li > a {
        color: $dark-color;
      }
    }
  }

  .mega-menubg {
    background: $dark-theme;
    box-shadow: 0 8px 16px 0 $dark-shadow;
    border-color: $dark-border;
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a,
  .mega-menubg.hor-mega-menu h3 {
    color: $dark-color;
  }

  .main-profile-menu .dropdown-item + .dropdown-item {
    border-top-color: $dark-border;
  }

  .rating-scroll h6 {
    color: $dark-color;
  }

  .main-calendar .fc-list-empty {
    background-color: $dark-theme;
    border-color: $dark-border;
  }

  .form-control::placeholder {
    color: $dark-color !important;
  }

  .header-icon,
  .main-header-message .nav-link i,
  .main-header-notification .nav-link i,
  .nav-item.full-screen .nav-link i,
  .sales-flot .flot-chart .flot-x-axis > div span:last-child,
  .p-text .p-name {
    color: $dark-color;
  }

  .sidebar {
    .tab-menu-heading {
      background: $dark-theme2;
    }

    .tabs-menu ul li {
      a {
        border-color: $dark-border;
        background: $dark-theme;
        color: $dark-color;
      }

      .active {
        color: $dark-color;
      }
    }
  }

  .datepicker > {
    .datepicker_inner_container > {
      .datepicker_calendar,
      .datepicker_timelist {
        background-color: $dark-theme;
        border-color: $dark-border;
      }
    }

    .datepicker_header {
      background-color: $dark-theme2;
      color: $dark-color;
    }
  }

  .main-datetimepicker {
    border-color: $dark-border;

    > .datepicker_inner_container > {
      .datepicker_calendar th {
        color: $dark-color;
      }

      .datepicker_timelist {
        border-left-color: $dark-border;
        border-right-color: $dark-border;

        > div.timelist_item {
          &:hover,
          &:focus {
            background-color: $dark-theme2;
          }
        }
      }
    }
  }

  .datepicker > .datepicker_inner_container > .datepicker_timelist > div.timelist_item.hover {
    color: $dark-color;
    background-color: $dark-theme2;
  }

  .datetimepicker {
    .datetimepicker-days table thead tr:last-child th {
      color: $dark-color;
    }

    table {
      th.dow {
        background: transparent;
      }

      td {
        &.old,
        &.new {
          color: $dark-color;
        }

        background: transparent;
        color: $dark-color;
      }

      th {
        &.prev,
        &.next,
        &.switch {
          background-color: transparent;
          color: $dark-color;
        }

        &.prev:hover {
          background-color: $dark-theme;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .dark-theme {
    .horizontalMenu > .horizontalMenu-list {
      background: $dark-theme;

      > li > a {
        border-bottom-color: $dark-border;
      }
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li:hover .sub-icon {
      background: $dark-theme2;
      border-bottom-color: $dark-border;
    }

    .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > {
      a:hover:before {
        border-color: $dark-border;
      }

      ul.sub-menu > li > a:hover {
        color: $dark-color !important;
        background-color: $dark-theme2;
      }
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li:hover a:before {
      border-color: $dark-border !important;
      color: $dark-color !important;
    }

    .mega-menubg {
      li a:before {
        border-color: $dark-border;
      }

      background: $dark-theme !important;
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a {
      background: $dark-theme !important;
      color: $dark-color !important;
    }
  }
}

.datetimepicker table th.prev:focus {
  background-color: $dark-theme;
}

.dark-theme {
  .datetimepicker table th {
    &.next {
      &:hover,
      &:focus {
        background-color: $dark-theme;
      }
    }

    &.prev span::before,
    &.next span::before {
      color: $dark-color;
    }

    &.switch {
      &:hover,
      &:focus {
        background-color: $dark-theme;
      }
    }
  }

  .iti__selected-flag {
    border-right-color: $dark-border;
    border-left-color: $dark-border;
  }

  .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
    background-color: $dark-theme2;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
    background: $dark-theme;
  }

  .dashboard-carousel .btn-icons {
    background: $dark-theme2 !important;
    color: $dark-color;
  }

  .btn-light {
    &:focus,
    &.focus {
      box-shadow: 0 0 0 0.2rem $dark-shadow;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $dark-color;
        background-color: $dark-theme2;
        border-color: $dark-border;
      }
    }
  }

  .show > .btn-light.dropdown-toggle {
    color: $dark-color;
    background-color: $dark-theme2;
    border-color: $dark-border;
  }

  .email-media .media-body {
    small,
    .media-title {
      color: $dark-color;
    }
  }

  .page-item.disabled .page-link {
    color: $dark-color;
    background: $dark-theme2;
  }

  .chat {
    .msg_cotainer,
    .msg_cotainer_send {
      background-color: $dark-theme2;
    }

    .dropdown-menu li {
      color: $dark-color;

      &:hover {
        background: $dark-theme2;
      }
    }
  }

  #basic .breadcrumb {
    background-color: $dark-theme2;
  }

  .latest-tasks .tasks .span,
  .list-group-item-action,
  .list-group-item.active,
  .bg-info p.card-text,
  .main-content-body.main-content-body-contacts .media-body h5 {
    color: $dark-color;
  }

  .table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: transparent;
  }

  .dataTables_wrapper .dataTables_filter input {
    color: $dark-color;

    &::placeholder {
      color: $dark-color;
    }
  }

  .iti__divider {
    border-bottom-color: $dark-border;
  }

  .iti__country.iti__highlight {
    background-color: $dark-theme;
  }

  .iti__country-list {
    background-color: $dark-theme;
    box-shadow: 0 8px 16px 0 $dark-shadow;
  }

  .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
    background-color: $dark-theme2;
  }

  .price.panel-color > .panel-body {
    background-color: $dark-theme;
    border-color: $dark-border;
  }

  table.dataTable tbody td.sorting_1 {
    background: $dark-theme2;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
    color: $dark-color;

    &:hover,
    &:focus {
      color: $dark-color;
    }
  }

  .dataTables_paginate .pagination .page-link {
    border-color: $dark-border;
  }

  .chat {
    abbr.timestamp,
    .msg_time,
    .msg_time_send {
      color: $dark-color;
    }
  }

  .nav-tabs {
    border-bottom-color: $dark-border;

    .nav-link.active {
      border-color: $dark-border;
    }
  }

  .page-link {
    border-color: $dark-border;
  }

  .popover-head-primary .popover-header,
  .popover-head-secondary .popover-header,
  .popover-primary .popover-header,
  .popover-secondary .popover-header {
    color: $dark-color !important;
  }

  .main-card-signin {
    box-shadow: 0 1px 15px 1px $dark-shadow;
  }

  .page-item.disabled .page-link {
    border-color: $dark-border;
    background: $dark-theme2;
  }

  .ps > .ps__rail-y {
    background-color: $dark-theme;
  }

  .close-toggle {
    color: $dark-color !important;
  }

  .slide.is-expanded {
    .side-menu__label,
    .side-menu__icon,
    .angle {
      color: $dark-color;
    }
  }

  .vmap-wrapper {
    background: $dark-theme !important;
  }

  .card-dashboard-eight .list-group-item {
    span {
      color: $dark-color;
    }
    border-bottom-color: $dark-border;
    border-color: $dark-border;
  }

  .sales-info h3,
  .card-table h4,
  .total-revenue h4 {
    color: $dark-color;
  }

  .product-timeline ul.timeline-1:before {
    border-left-color: $dark-border;
    border-right-color: $dark-border;
  }

  .main-dashboard-header-right > div h5 {
    color: $dark-color;
  }

  .customers {
    .list-group-item-action {
      &:hover,
      &:focus {
        background-color: $dark-hover;
      }
    }

    h5 {
      color: $dark-color;
    }
  }

  nav.prod-cat li a,
  .product-sale .wishlist {
    color: $dark-color;
  }

  .shopping-cart-footer {
    border-top-color: $dark-border;
  }

  .select2-dropdown {
    border-color: $dark-border;
  }

  .sidebar-right .list a {
    color: $dark-color;
  }

  .card-footer {
    background-color: $dark-theme !important;
  }

  .card.card-dark {
    border-top-color: $dark-border !important;
  }

  .nav-tabs.html-source .nav-link.active {
    border-color: $dark-border;
    background-color: $dark-theme2;
  }

  .toast-body {
    background: $dark-theme2;
  }

  .tabs-style-2 .main-nav-line .nav-link {
    background: $dark-theme !important;

    &.active {
      background: $dark-theme !important;
    }

    border-color: $dark-border;
  }

  .tabs-style-3 .nav.panel-tabs li a,
  .tabs-style-4 .nav.panel-tabs li a {
    background: $dark-theme2;
    color: $dark-color;
  }

  .nav-link.html-code {
    background: $dark-theme;
  }

  .nav-tabs .nav-link.html-code {
    &.active,
    &:hover,
    &:focus {
      background-color: $dark-theme2;
      color: $dark-color;
    }
  }

  .card .box {
    box-shadow: 0 0 25px $dark-shadow;
  }

  .userlist-table .user-link {
    color: $dark-color;
  }

  .main-chat-body .content-inner:before {
    background: $dark-theme2;
  }

  .left.main-msg-wrapper:before {
    color: $dark-color;
  }

  .icons-list-item {
    border-color: $dark-border;
  }

  .user-wideget-footer {
    background-color: $dark-theme;
    border-top-color: $dark-border;
  }

  .profile.navtab-custom {
    .active a {
      background: $dark-theme2;
      color: $dark-color;
    }

    li a {
      color: $dark-color;
      border-color: $dark-border;
    }

    a.active {
      background: $dark-theme2;
    }
  }

  .nav.prod-cat li a,
  .header-icon-svgs,
  .app-sidebar .side-item.side-item-category {
    color: $dark-color;
  }

  .product-pagination {
    .page-link {
      background-color: $dark-theme !important;
      border-color: $dark-border;
    }

    .page-item.disabled .page-link {
      border-color: $dark-border;
      background: $dark-theme2;
      color: $dark-color;
    }
  }

  .price span {
    color: $dark-color;
  }

  #list3 {
    .media-body h6 {
      color: $dark-color;
    }

    .list-group-item {
      border-left-color: $dark-border;
      border-right-color: $dark-border;
    }
  }

  #list8 .list-group-item,
  #list1 .list-group-item,
  #list8 .list-group-item {
    border-left-color: $dark-border;
    border-right-color: $dark-border;
  }

  .bg-gray-100.nav-bg .nav-tabs {
    border-bottom-color: $dark-border;

    .nav-link {
      background-color: $dark-theme2;
    }
  }

  .popover-static-demo .popover {
    box-shadow: -8px 12px 18px 0 $dark-shadow;
    border-color: $dark-border;
  }

  .heading-inverse {
    background-color: $dark-theme2;
  }

  .toast {
    box-shadow: -8px 12px 18px 0 $dark-shadow;
  }

  .tabs-style-1 {
    .dark-theme .border {
      border-color: $dark-border !important;
    }

    .main-nav-line .nav-link.active {
      color: $dark-color;
      border-color: $dark-border $dark-border $dark-theme;
    }
  }

  &.app.sidenav-toggled.sidenav-toggled-open {
    .side-menu__label,
    .side-menu__item.active .side-menu__label {
      color: $dark-color;
    }
  }

  .horizontalMenucontainer .side-menu__icon,
  &.horizontal-light .horizontalMenucontainer .side-menu__icon {
    color: $dark-color;
    fill: $dark-color;
  }

  .main-header {
    .dropdown-menu-left {
      box-shadow: 0px 0px 15px 1px $dark-shadow;
    }

    .dropdown.nav-itemd-none .dropdown-menu:after {
      border-bottom-color: 9px solid $dark-theme;
    }
  }

  .file-browser .btn-default {
    background: $dark-theme2;
    color: $dark-color;
    border-color: $dark-border;
  }

  .fc-datepicker.main-datepicker.hasDatepicker {
    border-color: $dark-border;
  }

  .ql-snow .ql-picker {
    &:hover .ql-picker-label,
    &:focus .ql-picker-label,
    &:hover .ql-picker-label,
    &:focus .ql-picker-label {
      color: $dark-color;
    }
  }

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border-color: $dark-border;
  }

  .main-mail-compose-body {
    background-color: $dark-theme;

    .form-group {
      + .form-group {
        border-top-color: $dark-border;
      }

      .form-control {
        background-color: $dark-theme2 !important;
      }

      .form-label,
      .nav-link {
        color: $dark-color;
      }
    }
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu {
    background-color: $dark-theme;
    box-shadow: 0 8px 16px 0 $dark-shadow;

    > li > a {
      color: $dark-color;
    }
  }

  .fc-non-business {
    background-color: $dark-theme2;
  }

  #external-events {
    background-color: $dark-theme2;
    border-color: $dark-border !important;
  }

  .fc-daygrid-event {
    background-color: $dark-theme2;
    border-color: $dark-border;
  }

  .fc-daygrid-dot-event {
    background-color: $dark-theme2;
    border-color: $dark-border !important;

    &:hover {
      background-color: $dark-theme2;
    }
  }

  .fc-list-day-cushion {
    background-color: $dark-theme;
  }

  .fc-theme-standard .fc-scrollgrid {
    border-color: $dark-border;
  }

  .fc .fc-daygrid-event-harness {
    border-color: $dark-border !important;
  }

  tr {
    td,
    th {
      border-color: $dark-border;
    }
  }

  .fc .fc-daygrid-day-number,
  table a {
    color: $dark-color;
  }

  .fc-theme-standard .fc-list {
    border-color: $dark-border;
  }

  .fc .fc-list-event:hover td {
    background-color: $dark-theme2 !important;
  }

  #external-events p {
    color: $dark-color !important;
  }

  .table-striped tbody tr:nth-of-type(2n + 1) {
    color: $dark-color;
  }

  &.dataTables_wrapper .dataTables_length select {
    border-color: $dark-border;
    color: $dark-color;
  }

  .bs-popover-end .popover-arrow::before {
    border-right-color: $dark-theme2 !important;
    border-left-color: $dark-theme2 !important;
  }

  .apexcharts-radialbar-track.apexcharts-track path {
    stroke: $dark-theme2;
  }

  .sa-icon.sa-custom {
    background-image: url("../../assets/img/brand/logo-white.png") !important;
  }

  .dropdown-menu-left {
    box-shadow: 0px 0px 15px 1px $dark-shadow;
  }

  .table.dataTable.no-footer {
    border-bottom-color: $dark-border !important;
  }

  .table-striped > tbody > tr:nth-of-type(odd) {
    --bs-table-accent-bg: $dark-theme2 !important;
  }

  .dataTables_wrapper .dataTables_length select {
    border-color: $dark-border;
  }

  .form-select {
    color: $white;
  }

  .apexcharts-radialbar .apexcharts-datalabel-value {
    fill: $dark-color !important;
    color: $dark-color !important;
  }

  .apexcharts-svg text {
    color: $dark-color !important;
    fill: $dark-color !important;
  }

  .layout-setting .header-icon-svgs,
  .resp-btn .header-icon-svgs,
  .demo-icon .header-icon-svgs {
    fill: $dark-color;
  }
   .events-title {
    color: $white;
  }
  .sidebar-right .event .Day {
    background:$dark-theme2;
    color: $white;
  }
  a {
    color: #bcc0c7;
 }
  .sidebar-right .latest-tasks .label {
    color: $white-8;
  }
 .setting-menu-footer .divider {
      background: $dark-theme2;
  }
}

@media only screen and (max-width: 991px) {
  .dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
    background-color: $dark-theme;
  }
}

@media only screen and (min-width: 992px) {
  .dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
    background: $dark-theme;
    box-shadow: 0 8px 16px 0 $dark-shadow;
  }
  
	.app.sidebar-mini.dark-theme .desktop-logo.logo-light {
		display: none;
	}
	.app.sidebar-mini.dark-theme .desktop-logo.logo-dark {
		display: block;
	}
	.app.sidebar-mini.dark-theme.sidenav-toggled .main-sidebar-header .desktop-logo.icon-logo {
		display: none;
		height: 2.5rem;
	}
	.app.sidebar-mini.dark-theme.sidenav-toggled .main-sidebar-header .logo-icon.icon-dark {
		display: block;
		height: 2.5rem;
	}
}

@media (min-width: 576px) {
  .dark-theme .main-calendar .fc-header-toolbar button {
    color: $dark-color;
  }
}

@media (max-width: 575px) {
  .dark-theme .main-calendar .fc-header-toolbar .fc-left button {
    color: $dark-color;

    &.fc-today-button {
      color: $dark-color;
    }
  }
}

@media (max-width: 605px) {
  .dark-theme .fc-view,
  .fc-view > table {
    border-color: transparent;
  }
}

@media (min-width: 992px) {
  .dark-theme.hoversubmenu.style1-leftmenu.sidenav-toggled .app-sidebar .side-menu-label1 {
    border-bottom-color: $dark-border;
    color: $dark-color !important;
  }
}

@media (min-width: 992px) {
  .dark-theme.app.sidebar-mini.sidenav-toggled-open .ps > .ps__rail-y > .ps__thumb-y {
    background-color: $dark-theme;
  }
}

@media only screen and (max-width: 991px) {
  .dark-theme .horizontalMenu > .horizontalMenu-list {
    border-right-color: $dark-border;
    border-left-color: $dark-border;
  }
}

@media (min-width: 992px) {
  .dark-theme .main-nav {
    &.main-nav-colored-bg .nav-link + .nav-link,
    .nav-link + .nav-link {
      border-left-color: $white-6;
      border-right-color: $white-6;
    }
  }
  .dark-theme.light-header {
    .main-header-message .nav-link i, 
     .main-header-notification .nav-link i, 
      .nav-item.full-screen .nav-link i, .header-setting-icon .nav-link i, .main-layout .dark-layout i, .main-layout .light-layout i {
      color: #282f53;
    }
  }
}

@media (max-width: 991px) {
  .dark-theme {
    .main-signup-header,
    .main-card-signin {
      border-color: $dark-border;
    }
  }
}

@media (min-width: 992px) {
  .horizontal.dark-theme {
    .horizontal-main {
      .slide {
        .slide-menu,
        .sub-slide-menu {
          background-color: $dark-theme !important;
          border-color: $dark-border;
          box-shadow: 0 3px 10px $dark-shadow !important;
        }
      }

      .sub-slide2 .sub-slide-menu1 {
        background-color: $dark-theme !important;
        border-color: $dark-border;
        box-shadow: 0 3px 10px $dark-shadow !important;
      }
    }
  }
}
.dark-theme {
  &.app.sidebar-mini .desktop-logo.logo-dark {
    display: block;
  }
  &.app.sidebar-mini .desktop-logo.logo-light {
    display: none;
  }
  .table-striped > tbody > tr:nth-of-type(odd) > * {
    color: $dark-color;
  }
  .app-sidebar__user .user-pro-body img {
    border-color: $dark-border;
    box-shadow: 0px 5px 5px 0px $dark-shadow;
    background: $dark-theme2;
  }
  .dark-layout {
    display: none;
  }
  .light-layout {
    display: block !important;
  }
  .navbar-toggler-icon {
    color: $dark-color;
  }
  @media (max-width: 991px) {
    .responsive-logo .header-logo {
      .logo-11 {
        display: none;
      }
      .dark-logo-1 {
        display: block;
      }
    }
  }
  @media (max-width: 991px) {
    .responsive-navbar.navbar .navbar-collapse {
      background: $dark-theme;
      border-bottom-color: $dark-border;
      border-top-color: $dark-border;
    }
  }
  @media (min-width: 992px) {
    &.app.sidebar-mini.sidenav-toggled .main-sidebar-header .logo-icon.icon-dark {
      display: block;
    }
    &.app.sidebar-mini.sidenav-toggled .main-sidebar-header .logo-icon.icon-light {
      display: none;
    }
    &.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .desktop-logo {
      display: none;
    }
    &.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .desktop-logo.logo-dark {
      display: block;
    }
  }
  @media (min-width: 992px) {
    &.app.sidenav-toggled .side-menu__label {
      color: $dark-color;
    }
  }
  @media (min-width: 992px) {
    &.hover-submenu1.app.sidenav-toggled-open .side-menu__label1 {
      border-bottom-color: $dark-border;
    }
  }
  .sub-side-menu__item {
    color: $dark-color;
  }
  .sub-slide-item2 {
    color: $dark-color;
  }
  .slide-menu a:before {
    color: $dark-color !important;
  }
  .apexcharts-tooltip.apexcharts-theme-light {
    border-color: $dark-border;
    background: $dark-theme2;
  }
  .main-dashboard-header-right > div label {
    color: $dark-color;
  }
  .left-content p {
    color: $dark-color;
    font-weight: 400;
  }
  .app-sidebar__user .user-info .text-muted {
    color: $dark-color !important;
    opacity: 0.8;
  }
  .main-footer .container-fluid {
    color: $dark-color;
  }
  @media (min-width: 992px) {
    &.horizontal .app-sidebar {
      border-top-color: $dark-border;
    }
  }
  .main-header form[role="search"] button[type="reset"] {
    background: $dark-theme2;
  }
  #slide-right,
  #slide-left {
    svg {
      fill: $white;
    }
  }
  .demo_changer .form_holder {
    background-color: $dark-theme;
    border-left-color: $dark-border;
    border-right-color: $dark-border;
  }
  .demo_changer h4 {
    border-bottom-color: $dark-border;
    background: $black-2;
    border-top-color: $dark-border;
    color: $white !important;
  }
  .demo_changer,
  .demo_changer p {
    color: $dark-color !important;
  }
  .input-color-picker {
    border-color: $dark-border;
    background-color: transparent;
  }
  .input-dark-color-picker {
    border-color: $dark-border;
    background-color: transparent;
  }
  .input-transparent-color-picker {
    border-color: $dark-border;
    background-color: transparent;
  }
  .form_holder img {
    border-color: $dark-border;
    background: transparent;
  }
  .sidebar-right1 {
    h4 {
      color: $white;
    }
  }
  .switch_section span {
    color: $dark-color !important;
  }
  p {
    color: $dark-color;
  }
  .text-dark {
    color: $white !important;
  }
  .icons-list-item svg {
    fill: $dark-color;
  }
  .main-table-reference {
    background-color: $dark-theme;
  }
  .main-contact-star:hover,
  .main-contact-star:focus {
    color: $dark-color;
  }
  .minus {
    background: url(../plugins/dtree/images/misc.png) -18px -59px no-repeat transparent;
  }
  .dTree a {
    color: $dark-color;
  }
  .main-nav-column .nav-link i:not([class*=" tx-"]) {
    color: $dark-color;
  }
  .file-image-1 {
    border-color: $dark-border;
  }
  .pro-img-box img {
    border-color: $dark-border !important;
  }
  .tag.tag-attachments-lg {
    border-color: $dark-border;
  }
  .tag.tag-attachments-sm {
    border-color: $dark-border;
  }
  .tag.tag-attachments {
    border-color: $dark-border;
  }
  .main-img-user1 {
    &.avatar-lg,
    &.avatar-md,
    &.avatar-sm {
      .number-badge {
        border-color: $dark-border;
      }
    }
  }
  .main-img-user3 {
    &.avatar-lg,
    &.avatar-md,
    &.avatar-sm {
      .icon-badgeavatar {
        border-color: $dark-border;
      }
    }
  }
  .badge.bg-white {
    color: $white !important;
  }
  #tab,
  #typography {
    .bg-gray-100 {
      background-color: $dark-theme !important;
    }
  }
  #popover,
  #popover2,
  #popover3 {
    .bg-gray-200 {
      background-color: $dark-theme !important;
    }
  }
  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    border-color: $dark-border;
  }
  .nav-tabs .nav-link.active {
    color: $white;
    background-color: $dark-theme !important;
    border-color: $dark-border;
  }
  .nav-tabs .nav-link{
    background: transparent;
  }
  .card-dashboard-events .list-group-item label,
  .card-dashboard-events .list-group-item label span{
    color: $white-6;
  }
  .bootstrap-tagsinput input {
    color: $dark-color;
  }
  .tabs-style-1 .panel-tabs {
    border-bottom-color: $dark-border;
  }
  .scrollspy-example {
    border-right-color: $dark-border;
    border-left-color: $dark-border;
    border-bottom-color: $dark-border;
  }
  .navbar-light .navbar-brand {
    color: $white;
  }
  #navbar-example3 .nav-link {
    color: $dark-color;
  }
  .scrollspy-example-2 {
    border-color: $dark-border;
  }
  .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: $white;
  }
  .form-control::-webkit-file-upload-button {
    color: $dark-color;
    background-color: $dark-theme2;
  }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: $dark-theme;
  }
  .theme-container,
  .theme-container2,
  .theme-container1 {
    button {
      color: $dark-color;
      background-color: $dark-theme;
      border-color: $dark-border;
    }
  }
  .pcr-app {
    background: $dark-theme2;
  }
  .project-card .project-content ul span {
    color: $white;
 }
  .datepicker {
    box-shadow: 0.5px 0.5px 0px $dark-shadow;
    border-color: $dark-border;
    background-color: $dark-theme;
  }
  .datepicker table tr td span {
    background: $dark-theme;
  }
  .datepicker td {
    color: $dark-color;
  }
  .datepicker .datepicker-switch:hover,
  .datepicker .prev:hover,
  .datepicker .next:hover,
  .datepicker tfoot tr th:hover {
    color: $white;
  }
  .datepicker th {
    color: $white;
  }
   .card-dashboard-events .list-group-item h6 {
    color: $white-8;
  }
  .datepicker-days tbody {
    background: $dark-theme;
  }
  .text-secondary{
    color: $white-8 !important;
  }
  .ui-state-default,
  .ui-widget-content .ui-state-default,
  .ui-widget-header .ui-state-default,
  .ui-button {
    border-color: $dark-border;
    background: $dark-theme2;
    color: $dark-color;
  }
  .ui-widget.ui-widget-content {
    border-color: $dark-border;
  }
  .ui-datepicker .ui-datepicker-header .ui-datepicker-next,
  .ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
    color: $dark-color;
  }
  .ui-datepicker .ui-datepicker-calendar td a:hover {
    background-color: $dark-theme2;
    color: $white;
  }
  .datetimepicker {
    box-shadow: 0 0 6px $dark-shadow;
  }
  .datetimepicker-hours span.hour:hover,
  .datetimepicker-hours span.minute:hover,
  .datetimepicker-hours span.month:hover,
  .datetimepicker-hours span.year:hover {
    background: $dark-theme;
  }
  .datetimepicker-minutes span.hour:hover,
  .datetimepicker-minutes span.minute:hover,
  .datetimepicker-minutes span.month:hover,
  .datetimepicker-minutes span.year:hover {
    background: $dark-theme;
  }
  .select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: $dark-theme;
  }
  .select2-container--default {
    .select2-selection--single {
      .select2-selection__placeholder {
        color: $dark-color;
      }
    }
  }
  .intl-tel-input {
    border-color: $dark-border;

    .flag-dropdown {
      .selected-flag {
        background: $dark-theme2;

        &:hover {
          background-color: $dark-theme;
        }
      }
    }
  }

  .intl-tel-input {
    .flag-dropdown {
      .selected-flag .down-arrow {
        border-top-color: $dark-border;
      }

      .country-list {
        box-shadow: 1px 1px 4px $dark-shadow;
        background-color: $dark-theme2;

        .divider {
          border-bottom-color: $dark-border;
        }

        .country {
          .dial-code {
            color: $dark-color;
          }

          &.highlight {
            background-color: $dark-theme;
          }
        }
      }
    }

    input {
      border-color: $dark-border;
    }
  }
  .project-card .project-content ul strong {
    color: $white-8;
  }
  .sidebar-navs a {
    background: none;
    border-color: $dark-border !important;
    color: $white !important;
}
  #mobile-number {
    background-color: $dark-theme;
    color: $white;
  }
  .datepicker > .datepicker_inner_container {
    background-color: $dark-theme2;
    border-color: $dark-border;
    box-shadow: 0.5px 0px 3px $dark-shadow;
  }
  .main-datetimepicker > .datepicker_inner_container > .datepicker_calendar td.hover {
    background-color: $dark-theme;
  }
  .dropify-wrapper p:first-child {
    color: $dark-color !important;
  }
  .dropify-wrapper .dropify-message span.file-icon {
    color: $white;
  }
  .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary {
    color: $dark-color;
  }
  .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary .ff_fileupload_filename input {
    border-color: $dark-border;
    color: $dark-color;
    background: transparent;
  }
  .background {
    background-color: $dark-theme2 !important;
  }
  .main-dropdown-form-demo .dropdown-title {
    color: $white;
  }
  .form-select {
    border-color: $dark-border;
  }
  .was-validated .form-select:invalid,
  .form-select.is-invalid,
  input.form-select.parsley-error,
  textarea.form-select.parsley-error {
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        no-repeat right 0.75rem center/8px 10px,
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fa5c7c' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fa5c7c' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")
        $dark-theme no-repeat center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  .wizard > .steps .current a {
    color: $white;
  }
  .wizard > .steps .disabled a {
    color: $dark-color;
  }
  .note-icon-border {
    border-color: $dark-border;
  }
  .note-editor {
    &.note-frame,
    &.note-airframe {
      border-color: $dark-border;
    }

    &.note-frame .note-editing-area .note-editable[contenteditable="false"],
    &.note-airframe .note-editing-area .note-editable[contenteditable="false"] {
      background-color: $dark-theme2;
    }

    &.note-frame .note-editing-area .note-codable,
    &.note-airframe .note-editing-area .note-codable {
      color: $dark-color;
      background-color: $dark-theme2;
    }
    &.note-frame .note-status-output,
    &.note-airframe .note-status-output {
      color: $dark-color;
      border-top-color: $dark-border;
    }

    &.note-frame .note-status-output .text-muted,
    &.note-airframe .note-status-output .text-muted {
      color: $dark-color;
    }

    &.note-frame .note-status-output .alert,
    &.note-airframe .note-status-output .alert {
      color: $dark-color;
      background-color: $dark-theme2;
    }

    &.note-frame .note-statusbar,
    &.note-airframe .note-statusbar {
      background-color: $dark-theme2;
      border-top-color: $dark-border;
    }

    &.note-frame .note-statusbar .note-resizebar .note-icon-bar,
    &.note-airframe .note-statusbar .note-resizebar .note-icon-bar {
      border-top-color: $dark-border;
    }
  }

  .note-popover .popover-content,
  .note-editor .note-toolbar {
    background: $dark-theme2;
  }

  .note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-palette-title,
  .note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-palette-title {
    border-bottom-color: $dark-border;
  }

  .note-popover .popover-content .note-color .note-dropdown-menu .note-palette {
    .note-color-reset:hover,
    .note-color-select:hover {
      background: $dark-border;
    }
  }

  .note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette {
    .note-color-reset:hover,
    .note-color-select:hover {
      background: $dark-border;
    }
  }

  .note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-holder-custom .note-color-btn,
  .note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-holder-custom .note-color-btn {
    border-color: $dark-border;
  }

  .note-modal {
    .note-image-dialog .note-dropzone {
      color: $dark-color;
      border-color: $dark-border;
    }
  }

  .note-placeholder {
    color: $dark-color;
  }

  .note-handle .note-control-selection {
    border-color: $dark-border;

    .note-control-selection-bg {
      background-color: $dark-theme2;
    }

    .note-control-handle,
    .note-control-sizing,
    .note-control-holder {
      border-color: $dark-border;
    }

    .note-control-sizing {
      background-color: $dark-theme2;
    }

    .note-control-selection-info {
      background-color: $dark-theme2;
    }
  }

  .note-toolbar {
    background: $dark-theme2;
  }

  .note-btn-group .note-btn {
    border-color: $dark-border;
  }
  .note-editor.note-frame.panel.panel-default .panel-heading {
    background-color: $dark-theme2;
    border-bottom-color: $dark-border;
  }
  .note-btn.btn-default {
    background-color: $dark-theme;
    border-color: $dark-border;
  }
  .btn,
  .sp-container button {
    color: $dark-color;
  }
  .note-editor .btn-default:not(:disabled):not(.disabled):active {
    background-color: $dark-theme2;
  }
  .note-btn.btn-default:not(:disabled):not(.disabled).active {
    color: $white;
    background-color: $dark-theme2;
    border-color: $dark-border;
  }
  .richText {
    .richText-form {
      input {
        &[type="text"],
        &[type="file"],
        &[type="number"] {
          border-color: $dark-border;
        }
      }

      select {
        border-color: $dark-border;
      }

      button {
        color: $dark-color;
      }
    }

    .richText-toolbar {
      ul {
        border-bottom-color: $dark-border;

        li {
          a {
            color: $dark-color;

            .richText-dropdown-outer {
              .richText-dropdown {
                background-color: $dark-theme2;
                border-color: $dark-border;
                box-shadow: 0px 16px 18px rgba(6, 10, 48, 0.1);

                .richText-dropdown-close {
                  background: $dark-theme2;
                  color: $dark-color;
                }
              }

              ul.richText-dropdown {
                li {
                  a {
                    border-bottom-color: $dark-border;

                    &:hover {
                      background-color: $dark-theme2;
                    }
                  }

                  &.inline {
                    a {
                      box-shadow: 0 0 10px 0 $dark-shadow;
                    }
                  }
                }
              }
            }

            &:hover {
              background-color: $dark-theme;
            }
          }
        }
      }
    }

    .richText-editor {
      background-color: $dark-theme;
      border-left-color: $dark-border;
      border-right-color: $dark-border;

      table {
        td,
        th {
          border-color: $dark-border;
        }
      }

      &:focus {
        border-left-color: $dark-border;
        border-right-color: $dark-border;
      }
    }

    .richText-initial {
      background-color: $dark-theme2;
    }

    .richText-undo,
    .richText-redo {
      border-left-color: $dark-border;
      border-right-color: $dark-border;
    }

    .richText-help-popup {
      hr {
        border-top-color: $dark-border;
      }
    }

    .richText-list.list-rightclick {
      background-color: $dark-theme2;

      border-left-color: $dark-border;
      border-right-color: $dark-border;
      border-bottom-color: $dark-border;
    }

    border-color: $dark-border;
    background-color: $dark-theme2 !important;

    .richText-toolbar ul li a {
      border-left-color: $dark-border;
      border-right-color: $dark-border;
    }
  }
  .ql-wrapper-demo {
    background-color: $dark-theme2;
  }
  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover,
  .ql-snow.ql-toolbar button:focus,
  .ql-snow .ql-toolbar button:focus,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: $white;
  }
  .ql-snow.ql-toolbar button.ql-active {
    background-color: $dark-theme;
  }
  .ql-snow a {
    color: $white;
  }
  .ql-snow .ql-tooltip {
    background-color: $dark-theme2;
    border-color: $dark-border;
    box-shadow: 0px 0px 5px $dark-shadow;
    color: $dark-color;
  }
  .ql-snow .ql-tooltip::before {
    color: $dark-color;
  }
  .ql-snow .ql-tooltip a {
    color: $dark-color;
  }
  .dataTables_wrapper .dataTables_length .select2-container--default .select2-selection--single {
    border-color: $dark-border;
  }
  .dataTables_paginate .pagination .page-link {
    background-color: $dark-theme;
    border-color: $dark-border;
  }
  .card-img-holder {
    .card-footer {
      background-color: transparent;
    }
  }
  .widget-user .widget-user-image > img {
    border-color: $dark-border;
  }
  .border-start {
    border-right-color: $dark-border !important;
    border-left-color: $dark-border !important;
  }
  .border-end {
    border-right-color: $dark-border !important;
    border-left-color: $dark-border !important;
  }
  .latest-tasks .time {
    color: $dark-color;
  }
  .browser-stats .sub-text {
    color: $dark-color;
  }
  .bxl-instagram.tx-prime,
  .bxl-linkedin.tx-prime,
  .bxl-twitter.tx-prime,
  .bxl-facebook.tx-prime {
    background: $dark-theme2;
    border-color: $dark-border;
    color: $white;
  }
  color: $white;
  .profile-user .profile-edit {
    background: $dark-theme2;
  }
  hr {
    background: $dark-border;
    opacity: .2;
  }
  .panel-color {
    .list-group-item {
      background-color: $dark-theme;
    }
  }
  .content-title {
    color: $white;
  }
  .main-mail-subject span {
    color: $dark-color;
  }
  .main-mail-item:hover,
  .main-mail-item:focus {
    background-color: $dark-hover;
  }
  .main-mail-item.selected {
    background-color: $dark-theme2;
  }
  .main-mail-options .btn:hover,
  .main-mail-options .sp-container button:hover {
    background-color: $dark-theme2;
  }
  .main-mail-date {
    color: $dark-color;
  }
  .main-mail-options .btn.disabled,
  .main-mail-options .sp-container button.disabled {
    color: $dark-color;
  }
  .main-chat-list .media.selected {
    border-top-color: $dark-border;
    border-bottom-color: $dark-border;
  }
  .main-chat-list .media {
    border-bottom-color: $dark-border;
  }
  .main-chat-list .media:hover,
  .main-chat-list .media:focus {
    border-top-color: $dark-border;
    border-bottom-color: $dark-border;
  }
  .main-chat-footer {
    .form-control {
      background-color: transparent;
    }
  }
  .main-chat-list .media-body p {
    color: $dark-color;
  }
  .main-chat-list .media-contact-name span:last-child {
    color: $dark-color;
  }
  .main-chat-body .media-body > div:last-child {
    color: $dark-color;
  }
  .main-chat-msg-name small {
    color: $dark-color;
  }
  .shared-files {
    border-color: $dark-border;
  }
  .nice-select {
    color: $dark-color;
    background-color: $dark-theme2;
    border-color: $dark-border;

    &:after {
      border-bottom-color: $dark-color;
      border-right-color: $dark-color;
    }

    &.disabled {
      border-color: $dark-border;
      color: $dark-color;

      &:after {
        border-color: $dark-border;
      }
    }
    .list {
      background-color: $dark-theme2;
      box-shadow: 0 0 0 1px $dark-shadow;
    }

    .option {
      &:hover,
      &.focus {
        background-color: $dark-theme;
      }

      &.selected {
        &.focus {
          background-color: $dark-theme;
        }
      }

      &.disabled {
        color: $dark-color;
      }
    }
  }
  .card-item-desc .card-item-desc-1 dd {
    color: $dark-color;
  }
  .table {
    --bs-table-hover-color: $dark-color;
  }
  .product-details .table-bordered > :not(caption) > * {
    border-bottom-color: $dark-border;
  }
  @media (min-width: 768px) {
    #checkoutsteps > .steps .number {
      background-color: $dark-theme2;
      color: $dark-color;
      border-color: $dark-border;
    }
    #checkoutsteps .checkoutline {
      background-color: $dark-theme;
    }
  }
  #checkoutsteps > .steps a {
    color: $white;
  }
  #checkoutsteps .item {
    border-bottom-color: $dark-border;
  }
  #checkoutsteps .total {
    color: $white;
  }
  .handle-counter input {
    border-color: $dark-border;
    background-color: $dark-theme;
    color: $white;
  }
  .card-pay .tabs-menu.nav {
    background: $dark-theme;
  }
  .card-pay .tabs-menu.nav li {
    border-left-color: $dark-border;
    border-right-color: $dark-border;
  }
  .alert {
    .item-card {
      svg {
        fill: $dark-color;
      }
    }
  }
  .main-logo1 {
    color: $white;
  }
  .main-signup-header .form-control {
    color: $dark-color;
    border-color: $dark-border;
  }
  .main-signup-header label {
    color: $white;
  }
  .main-signup-header h2 {
    color: $white !important;
  }
  .main-signin-header h2 {
    color: $white;
  }
  .construction {
    h2 {
      color: $white;
    }
  }
  .main-signin-header label {
    color: $dark-color;
  }
  .main-signup-footer p {
    color: $dark-color;
  }
  .main-card-signin {
    background-color: $dark-theme;
  }
  &.error-3 {
    .bg-white {
      background-color: $dark-theme !important;
    }
  }
  .main-signin-header .form-control {
    color: $dark-color;
    border-color: $dark-border;
  }
  .main-table-reference {
    .bg-gray-100 {
      background-color: $dark-theme2 !important;
    }
  }
  .border-card {
    .bg-gray-100 {
      background-color: $dark-theme !important;
    }
  }
  .flex-card {
    .bg-gray-200,
    .bg-gray-300 {
      background-color: $dark-theme !important;
    }
  }
  .card-body .switch_section span {
    color: $dark-color !important;
  }
  @media (min-width: 992px) {
    &.app.sidenav-toggled .side-menu__item {
      border-right-color: transparent;
      border-left-color: transparent;
    }
  }
  .fc .fc-daygrid-day.fc-day-today {
    background-color: $dark-theme2;
  }
  .fc-timegrid-event-harness-inset .fc-timegrid-event {
    box-shadow: 0px 0px 0px 1px $dark-shadow;
  }
  .fc-theme-standard .fc-list-day-cushion {
    background-color: $dark-theme;
  }
  .fc-theme-standard .fc-list-day-cushion .fc-list-day-text,
  .fc-theme-standard .fc-list-day-cushion .fc-list-day-side-text {
    color: $white !important;
  }
  .fc-list-event.fc-event {
    color: $dark-color !important;
  }
  .fc .fc-list-empty {
    background-color: $dark-theme2;
  }
  .owl-nav button {
    background: $dark-theme2 !important;
    opacity: 1 !important;
    border-color: $dark-border !important;
    box-shadow: 0 4px 15px $dark-shadow;
  }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot {
    color: $white;
  }
  .alert {
    border-color: $dark-border;
  }
  #basic,
  #custom,
  #divider,
  #center,
  #right {
    @media (max-width: 568px) {
      .example {
        border-color: $dark-border;
      }
    }
  }
  .breadcrumb-item a {
    color: $dark-color;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    color: $dark-border;
  }
  .breadcrumb-item.active {
    color: $white;
  }
  .btn:focus-visible {
    outline: 0 !important;
  }
  .btn-light:not(:disabled):not(.disabled):active:focus,
  .btn-light:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem $dark-shadow;
  }
  .toast-body {
    color: $dark-color;
  }
  .tabs-style-3 {
    .tabs-menu-body {
      border-color: $dark-border;
    }
  }
  .tabs-style-4 .tabs-menu-body {
    border-color: $dark-border;
  }
  .tab_wrapper.right_side .content_wrapper {
    border-color: $dark-border;
  }
  .tab_wrapper.right_side > ul li.active {
    border-color: $dark-border;
  }
  .tab_wrapper > ul li {
    border-color: $dark-border;
    border-top-color: $dark-border;
  }
  .tab_wrapper.right_side > ul {
    border-bottom-color: $dark-border;
  }
  .tab_wrapper.right_side > ul li:after {
    background: $dark-theme2;
  }
  .ql-snow .ql-tooltip input[type="text"] {
    border-color: $dark-border;
    background: $dark-theme2;
  }
  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    color: $dark-color;
  }
  .datetimepicker table span.active,
  .datetimepicker table td.active {
    background: $dark-theme;
  }
  .dropify-wrapper:hover {
    background-image: linear-gradient(
      -45deg,
      $dark-theme 25%,
      transparent 25%,
      transparent 50%,
      $dark-theme 50%,
      $dark-theme 75%,
      transparent 75%,
      transparent
    );
  }
  .border-end-0 {
    border-right-color: $dark-border !important;
    border-left-color: $dark-border !important;
  }
  .border-start-0 {
    border-right-color: $dark-border !important;
    border-left-color: $dark-border !important;
  }
  .was-validated .form-select:valid,
  .form-select.is-valid,
  input.form-select.parsley-success,
  textarea.form-select.parsley-success {
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        no-repeat right 0.75rem center/8px 10px,
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300cccc ' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
        transparent no-repeat center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  .table.dtr-details {
    border-color: $dark-border;
  }
  table.dataTable > tbody > tr.child ul.dtr-details > li {
    border-bottom-color: $dark-border;
  }
  @media (max-width: 1143px) {
    .table tr td:last-child {
      border-left-color: $dark-border;
    }
  }
  .description-block > .description-text {
    color: $dark-color;
  }
  .settings-main-icon i {
    color: $white;
  }
  #checkoutsteps .item .thumb {
    border-color: $dark-border;
  }
  @media (min-width: 768px) {
    #checkoutsteps .checkoutline {
      background-color: $dark-theme;
    }
  }
  #checkoutsteps > .steps a {
    color: $dark-color;
  }
  #checkoutsteps .item .thumb {
    border-color: $dark-border;
  }
  #checkoutsteps .item {
    border-bottom-color: $dark-border;
  }
  .wizard > .content > .title {
    color: $dark-color;
  }
  .card-pay .tabs-menu.nav li {
    border-right-color: $dark-border;
    border-left-color: $dark-border;
  }
  .card-pay .tabs-menu.nav {
    background: $dark-theme;
  }
  @media (min-width: 768px) {
    #checkoutsteps > .steps .number {
      background-color: $dark-theme;
      color: $dark-color;
      border-color: $dark-border;
    }
  }
  @media (max-width: 767px) {
    #checkoutsteps > .steps .number {
      background-color: $dark-theme;
      color: $dark-color;
    }
  }

  @media (max-width: 767px) {
    #checkoutsteps .checkoutline {
      background-color: $dark-theme;
    }
  }
  @media (max-width: 767px) {
    .card-pay .tabs-menu li {
      border-bottom-color: $dark-border;
    }
  }
  .sign-favicon-a {
    display: none;
  }
  .sign-favicon-b {
    display: block;
  }
  .main-star i {
    color: $dark-color;
  }
  .SumoSelect > .optWrapper.multiple > .MultiControls > p:hover {
    background-color: $dark-theme;
  }
  .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button {
    border-color: $dark-border;
    background-color: $dark-theme;
    opacity: 1;
  }
  .SumoSelect > .CaptionCont > span.placeholder {
    color: transparent;
  }
  .main-nav-column .nav-link span {
    color: $dark-color;
  }
  .dropdown-menu-start {
    box-shadow: 0px 0px 15px 1px $dark-shadow;
  }

  .sidebar {
    border-left-color: $dark-border;
    border-right-color: $dark-border;
  }
  @media (min-width: 992px) {
    &.horizontal .app-sidebar {
      border-bottom-color: $dark-border !important;
      border-top-color: $dark-border;
    }
  }
}
.hover-submenu,
.hover-submenu1 {
  @media (min-width: 992px) {
    &.dark-theme {
      &.app.sidenav-toggled-open .slide-menu {
        background: $dark-theme;
        border-color: $dark-border;
        box-shadow: 8px 8px 20px $dark-shadow !important;
      }

      .side-menu__label {
        color: $dark-color;
      }

      .app-sidebar .slide-menu .slide-item:before {
        color: $dark-color;
      }
      &.app.sidebar-mini.sidenav-toggled-open .main-sidebar-header .logo-icon.icon-dark {
        display: block !important;
      }
      &.app.sidebar-mini.sidenav-toggled-open .main-sidebar-header .logo-icon.icon-light {
        display: none !important;
      }
      &.app.sidenav-toggled-open .side-menu__label1 a {
        border-bottom-color: $dark-border;
        color: $dark-color;
    }
    }
  }
}

.dark-theme .projects-stat .table-bordered th, .dark-theme .projects-stat .table-bordered td {
  border: 0;
}
.dark-theme .projects-stat .table th, .dark-theme .projects-stat .table td {
  border-top: 0;
}
.dark-theme .projects-stat .table thead th {
  border-bottom: 0;
  border-top: 0;
}
.dark-theme .projects-stat .table-responsive > .table-bordered, .dark-theme .projects-stat .alert-default {
  border: 0;
}
@media (max-width: 991px){
	.app.sidebar-mini.dark-theme .responsive-logo .logo-1{
		display: block;
		margin: 0 auto;
		left: 0;
		right: 0;
		text-align: center;
		position: absolute;
	}
	.app.sidebar-mini.dark-theme .responsive-logo .logo-11{
		display: none;
	}
  .dark-theme.light-header{
    &.app.sidebar-mini .responsive-logo .logo-1{
     display: none;
    }
    &.app.sidebar-mini .responsive-logo .logo-11{
     display: block;
    }
     .open-toggle svg g, .close-toggle svg g{ 
      fill: #282f53;
     }
     .navbar-toggler-icon {
      color: #5b6e88;
     }

  }
  
}

  
.dark-theme {
  .wideget-user-tab .tab-menu-heading{
    background: $dark-theme;
  }
  .wideget-user-tab .tab-menu-heading .nav li a{
    color: $white-8;
  }

    .navbar-form.active .input-group-btn .btn i {
      color: $white !important;
  }
  .main-contact-item {
    border-bottom-color: $dark-border;
 }
 .btn-white:hover{
   background-color: transparent;
   border-color: transparent;
 }
 .bg-primary-transparent{
  background-color: $dark-theme2 ;
 }
 .file-image-btn{
   border-color: $dark-border;
 }
  .file-image-btn .file-name-1 {
    color: $dark-color;
  }
  .main-icon-list{
    border-color: $dark-border;
  }
  .breadcrumb-item1.active {
    color: $dark-color;
  }
  .accordion .card-header a {
    border-bottom-color: $dark-border;
  }
  .accordion-dark .card-header a.collapsed:hover, .accordion-dark .card-header a.collapsed:focus {
    background-color:$dark-theme2;
  } 
  .custom-file-label::after {
    background-color:$dark-theme2;
  }
  .bg-gray-100{
    background-color:$dark-theme2;
  }
  .main-form-group,.form-switch-indicator,.custom-control-label::before{
    background-color:$dark-theme2;
  }
  .form-switch-indicator-lg,.form-switch-indicator-xl,.custom-control-label::before,.main-invoice-list .media+.media::before
  ,.preview-thumbnail.nav-tabs li,.preview-pic{
    border-color: $dark-border;
  }
  .bg-gray-200,.bg-gray-300{
    background-color: $dark-theme2;
  }
  .bg-gray-400 {
    background-color: $dark-theme2;
  }
  .bg-gray-500 {
    background-color: $dark-theme2;
  }
  .main-signin-header .form-control {
    border-color: $dark-border;
  }
  &.rtl .demo_changer .form_holder{
    border-right-color: $dark-border; 
  }
  .activity-list:before {
    content: " ";
    border-color: $dark-border; 
  }
  .bg-gray-900 {
    background-color: $dark-theme2;
  }
  .carousel-inner .carousel-item .thumb{
    border: 1px solid $white-2;
  }
  &.light-menu{
      .app-sidebar__user .user-info .text-muted {
        color: #7987a1 !important;
    }
    .sidebar-navs a {
        background: rgba(234, 235, 240, 0.2);
        border: 1px solid #e0e0f3 !important;
        color: #747684 !important;

    } 
     .side-menu .side-menu__icon {
      color: #2d3144;
      fill: #2d3144;
    }
     .slide.is-expanded a {
        color: #6d7790;
    }
    .slide-item {
        color: #6d7790;
    }
    .angle,.sub-angle{
       color: #5b6e88 !important;
    }
     .sub-side-menu__item {
      color: #6d7790;
  }
  }
  
  &.light-menu.horizontal{
    .slide.is-expanded a{
      color: $black-8;
    }
    .slide-item {
       color: $black-8;
    }
    .slide-menu a:before{
      color: $black-8 !important;
    }
    .sub-angle{
       color:  $black-8 !important;
    }
     #slide-right svg, #slide-left svg {
        fill: $black;
    }
  }
  &.color-menu {
      .app-sidebar,.main-sidebar-header {
        border-right-color: $dark-border !important;
        border-left-color: $dark-border !important;  
      }
      &.hover-submenu.app.sidenav-toggled-open .slide-menu,
      &.hover-submenu1.app.sidenav-toggled-open .slide-menu {
        background: $primary;
        border-color: rgba(255, 255, 255, 0.2);
        box-shadow: 8px 8px 17px rgb(0 0 0 / 10%);
    }
    &.horizontal .horizontal-main .slide .slide-menu,
    &.horizontal .horizontal-main .slide .sub-slide-menu,
    &.horizontal .horizontal-main .sub-slide2 .sub-slide-menu1 {
        background-color: $primary !important;
        border-color: rgba(255, 255, 255, 0.2);
    }
  }
  &.gradient-menu {
      .app-sidebar,.main-sidebar-header {
        border-right-color: $dark-border !important;
        border-left-color: $dark-border !important;  
      }
      &.hover-submenu.app.sidenav-toggled-open .slide-menu,
      &.hover-submenu1.app.sidenav-toggled-open .slide-menu {
        background: $primary-gradient;
        border-color: rgba(255, 255, 255, 0.2);
        box-shadow: 8px 8px 17px rgb(0 0 0 / 10%);
    }
    &.horizontal .horizontal-main .slide .slide-menu,
    &.horizontal .horizontal-main .slide .sub-slide-menu,
    &.horizontal .horizontal-main .sub-slide2 .sub-slide-menu1 {
        background:$primary-gradient !important;
        border-color: rgba(255, 255, 255, 0.2);
    }
  }
  &.light-menu {
      &.hover-submenu.app.sidenav-toggled-open .slide-menu,
      &.hover-submenu1.app.sidenav-toggled-open .slide-menu {
        background: $white;
        border-color: rgba(255, 255, 255, 0.2);
        box-shadow: 8px 8px 17px rgb(0 0 0 / 10%);
    }
    &.horizontal .horizontal-main .slide .slide-menu,
    &.horizontal .horizontal-main .slide .sub-slide-menu,
    &.horizontal .horizontal-main .sub-slide2 .sub-slide-menu1 {
        background-color: $white !important;
        border-color: rgba(255, 255, 255, 0.2);
    }
  }
}
@media (min-width: 992px){
  .light-menu.dark-theme{
      &.app.sidebar-mini.sidenav-toggled .main-sidebar-header .logo-icon.icon-dark{
        display: none !important;
      }
      &.app.sidebar-mini.sidenav-toggled .main-sidebar-header .logo-icon.icon-light{
        display: block !important;
      }
      &.app.sidebar-mini.sidenav-toggled.sideicon-menu.sidenav-toggled-open .main-sidebar-header .logo-icon.icon-light{
       display: none !important;
      }
      &.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .main-sidebar-header .logo-icon.icon-light{
       display: none !important;
      }
      &.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .desktop-logo.logo-light {
       display: none !important;
      }
      &.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .desktop-logo.logo-light {
       display: block !important;
      }
      &.hover-submenu.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .logo-icon.mobile-logo.icon-light{
        display: block !important;
      }
      &.hover-submenu.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .desktop-logo.logo-light {
       display: none !important;
      }
      &.hover-submenu1.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .logo-icon.mobile-logo.icon-light{
       display: block !important;
      }
      &.hover-submenu1.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .main-sidebar-header .main-logo {
        display: none !important;
      }
      &.hover-submenu1.app.sidenav-toggled-open .side-menu__label1 a {
        border-bottom-color:$black-1 !important;
        color: $primary;
      }
  }
 
 }
 @media (max-width: 991px){
 .light-header.dark-theme.horizontal .responsive-logo .header-logo .logo-11 {
    display: block;
  }
}
