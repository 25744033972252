

.main-header {
	.input-group-btn .btn {
	  border: 0px solid #ebeaf1 !important;
	  background: transparent;
	  border-radius: 3px;
	  color: #3c4858;
	  height: 38px;
	  width: 38px;
	  line-height: 24px;
	  border-radius: 50px;
	  font-size: 22px;
  
	  &:hover {
		border-radius: 50%;
		color: $primary;
  
		i {
		  color: $black;
		}
	  }
  
	  i {
		font-size: 16px;
		color: #3c4858;
	  }
	}
  
	.navbar-form.active .input-group-btn .btn i {
	  font-size: 14px;
	  line-height: 27px;
	}
  }
  
  .navbar-form.active .input-group-btn {
	position: absolute;
	right: 5px;
	z-index: 99999;
	top:3px;
  }
  .navbar-form .input-group-btn .btn i {
    color: $white !important;
}
  
  
  .main-header form[role="search"] {
	top: 0px;
	right: 0;
	width: 100%;
	padding: 0px;
	margin: 0px;
	z-index: 0;
  
	button {
	  padding: 5px 12px;
	  border-radius: 0px;
	  border-width: 0px;
	  color: #6f7282;
	  background-color: rgb(248, 248, 248);
	  border-color: rgb(231, 231, 231);
	  box-shadow: none;
	  outline: none;
	}
  
	input {
	  padding: 5px 12px;
	  border-radius: 0px;
	  border-width: 0px;
	  color: #6f7282;
	  background-color: rgb(248, 248, 248);
	  border-color: rgb(231, 231, 231);
	  box-shadow: none;
	  outline: none;
	  padding: 16px 12px;
	  font-size: 14px;
	  color: #807b90;
	  box-shadow: none;
	}
  
	button[type="reset"] {
	  display: none;
	  background: $white;
	  border: 0 !important;
	}
  
	width: 38px;
  
	input {
	  font-size: 16px;
	  opacity: 0;
	  display: none;
	  height: 66px;
	  transition: all 05s;
	}
  
	&.active {
	  width: 100%;
	  z-index: 9999;
	  right: 0;
	  border-left: 0;
	  position: absolute;
	  left: 0;
  
	  button {
		display: table-cell;
		opacity: 1;
	  }
  
	  input {
		display: table-cell;
		opacity: 1;
		width: 100%;
		height: 64px;
		background: $white;
		padding-left: 20px;
		border-radius: 0;
		transition: all 05s;
		box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.1);
		top:0px;
	  }
	}
  }
  
  @media (min-width: 768px) {
	.dropdown {
	  &.d-cart .dropdown-menu, &.message .dropdown-menu {
		width: 20.5rem !important;
	  }
	}
  }
  
  .nav .nav-item .dropdown-menu {
	.dropdown-item.dropdown-header {
	  background: #1b2d8f;
	  height: 85px;
	  color: $white;
	}
  
	top: 49px;
	border-radius: 0;
	min-width: 245px;
	-webkit-box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
	box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
  }
  
  .main-header {
	&.nav.nav-item .dropdown-menu {
	  border: 0 !important;
	}
  
	.input-group-btn .btn i {
	  font-size: 1.1rem;
	  color: $white;
	  line-height: 0.8;
	}
  }
  
  .main-header-message .nav-link i, .main-header-notification .nav-link i, .nav-item.full-screen .nav-link i ,.header-setting-icon  .nav-link i,.main-layout .dark-layout i,.main-layout .light-layout i{
	text-align: center;
	line-height: 40px;
	line-height: 0.3;
	font-size: 1.1rem;
	color: $white;
	margin: 0 auto;
  }
  
  .main-header-message > a, .main-header-notification > a, .nav-item.full-screen > a, .header-setting-icon > a,.main-layout .dark-layout,.main-layout .light-layout{
	display: block;
	font-size: 20px;
	color: $gray-900;
	position: relative;
	line-height: 0;
	outline: none;
	padding: 1rem 0.5rem;
	border: 1px solid $white-2;
	margin: 0 7px;
	border-radius: 50%;
	height: 38px;
	width: 38px;
	text-align: center;
  }
  
  .main-header-message > a, .main-header-notification > a, .nav-item.full-screen > a {
	padding: 0.95rem 0.5rem;
  }
  
  .main-header-message.right-toggle .nav-link {
	padding: 0.1rem 0.7rem;
  }
  
  .main-header {
	&.hor-header {
	  > .container-fluid {
		padding-left: 25px;
		padding-right: 25px;
		width: 1200px;
	  }
  
	  border-bottom: 0;
	  box-shadow: none;
	  margin-bottom: 0;
	}
  
	background-color: transparent;
	z-index: 100;
	height: 64px;
	border-bottom: 1px solid #dee4ec;
	z-index: 999;
	width: 100% !important;
	position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  
	> {
	  .container, .container-fluid {
		height: 100%;
		display: flex;
		align-items: center;
		padding-left: 20px;
		padding-right: 20px;
		width: 100%;
	  }
	}
  
	.main-header-left .main-logo {
	  display: none;
	}
	
  }
  
  @media (max-width: 991px) {
	.main-content-body-show .main-header-arrow {
	  display: block !important;
	  padding: 10px 20px;
	  background: $primary;
	  color: $white;
	}
  }
  
  @media (max-width: 991.98px) {
	.main-header-menu-show {
	  overflow: hidden;
  
	  .main-header-menu {
		border-right: 1px solid rgba(28, 39, 60, 0.08);
		box-shadow: 0 0 30px rgba(28, 39, 60, 0.08);
		transform: translateX(0);
	  }
  
	  .main-navbar-backdrop {
		visibility: visible;
		opacity: 1;
	  }
	}
  }
  
  @media (min-width: 992px) {
	.main-header > {
	  .container, .container-fluid {
		padding-left: 10px;
		padding-right: 10px;
	  }
	}
  }
  
  @media (min-width: 992px) {
	.main-header > .container {
	  padding: 0;
	}
  }
  
  @media (max-width: 991.98px) {
	.main-header > .container {
	  max-width: none;
	 
	}
  
	.horizontalMenucontainer .main-header.hor-header.header-sticky {
	  box-shadow: 0 12px 10px -1px rgb(144, 146, 173);
	}
  }
  
  @media (max-width: 991px) {
	.main-header-left #dropdownMenuButton2,.main-header-left #dropdownMenuButton3 {
	  display: none;
	}
  }
  
  
  @media (min-width: 992px) {
	.main-header .main-header-left .main-logo {
	  display: flex;
	}
  }
  
  .main-header-left {
	display: inherit;
	align-items: center;
  
	.list-items {
	  li {
		display: inline-block;
  
		i {
		  display: block;
		  font-size: 24px;
		  color: $gray-900;
		  position: relative;
		  line-height: .9;
		  outline: none;
		  margin-right: 15px;
		}
	  }
  
	  margin-bottom: 0;
	}
  
	.main-header-arrow {
	  display: none;
	  position: relative;
	  top: -2px;
	}
  }
  
  .main-header .nav-item.full-screen.fullscreen-button {
	margin: auto 0;
  }
  
  .main-header-menu-icon {
	margin-right: 10px;
	width: 20px;
	height: 30px;
	display: flex;
	align-items: center;
  
	span {
	  display: block;
	  position: relative;
	  width: 15px;
	  height: 2px;
	  background-color: $gray-900;
	  border-radius: 3px;
	  transition: all 0.2s ease-in-out;
  
	  &::before, &::after {
		content: '';
		position: absolute;
		left: 0;
		width: 20px;
		height: 2px;
		background-color: $gray-900;
		border-radius: 3px;
		transition: all 0.2s ease-in-out;
	  }
  
	  &::before {
		top: -6px;
	  }
  
	  &::after {
		bottom: -6px;
	  }
	}
  }
  
  @media (min-width: 992px) {
	.main-header-menu-icon {
	  margin-right: 25px;
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.main-header-menu-icon span {
	  transition: none;
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.main-header-menu-icon span {
	  &::before, &::after {
		transition: none;
	  }
	}
  }
  
  .main-header-menu {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: 240px;
	background-color: $white;
	overflow-y: auto;
	z-index: 1000;
	transform: translateX(-240px);
	transition: all 0.35s;
  
	> .nav {
	  flex-direction: column;
	  padding: 20px;
	}
  
	.nav-item {
	  &::before {
		content: '';
		position: absolute;
		top: 67px;
		margin-left: 25px;
		width: 15px;
		height: 15px;
		border-top: 2px solid rgba(28, 39, 60, 0.12);
		border-left: 2px solid rgba(28, 39, 60, 0.12);
		background-color: $white;
		transform: rotate(45deg);
		z-index: 100;
		display: none;
	  }
  
	  + .nav-item {
		margin-top: 10px;
	  }
  
	  > .nav-link {
		display: flex;
		align-items: center;
		position: relative;
		color: $default-color;
		font-size: 15px;
		font-weight: 500;
		padding: 0;
		transition: all 0.2s ease-in-out;
  
		&:hover {
		  color: $primary;
  
		  i {
			color: $primary;
		  }
		}
  
		&.with-sub::after {
		  content: '\f3d0';
		  display: inline-block;
		  font-family: 'Ionicons';
		  font-size: 12px;
		  font-weight: 400;
		  margin-left: auto;
		  margin-top: 3px;
		  opacity: .5;
		}
  
		.typcn {
		  font-size: 20px;
		  line-height: .95;
		  margin-right: 15px;
		  width: 16px;
  
		  &::before {
			width: auto;
		  }
		}
	  }
  
	  &.active > .nav-link {
		color: $primary;
		position: relative;
  
		&::before {
		  content: '';
		  position: absolute;
		  bottom: 0;
		  top: 0;
		  left: -20px;
		  width: 2px;
		  background-color: $primary;
		  border-radius: 3px;
		}
	  }
  
	  &.show .main-menu-sub {
		display: block;
	  }
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.main-header-menu {
	  transition: none;
	}
  }
  
  @media (min-width: 992px) {
	.main-header-menu {
	  background-color: transparent;
	  border-right: 0;
	  position: static;
	  width: auto;
	  overflow-y: visible;
	  transform: none;
	  z-index: auto;
	}
  }
  
  @media (min-width: 992px) {
	.main-header-menu > .nav {
	  padding: 0;
	  flex-direction: row;
	  align-items: center;
	}
  }
  
  @media (min-width: 992px) {
	.main-header-menu .nav-item + .nav-item {
	  margin-top: 0;
	  margin-left: 30px;
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.main-header-menu .nav-item > .nav-link {
	  transition: none;
	}
  }
  
  @media (min-width: 992px) {
	.main-header-menu .nav-item > .nav-link {
	  font-size: 0.875rem;
	}
  }
  
  @media (min-width: 992px) {
	.main-header-menu .nav-item > .nav-link.with-sub::after {
	  margin-left: 5px;
	}
  }
  
  @media (min-width: 992px) {
	.main-header-menu .nav-item > .nav-link .typcn {
	  font-size: 18px;
	  margin-right: 7px;
	}
  }
  
  @media (min-width: 992px) {
	.main-header-menu .nav-item.active > .nav-link::before {
	  top: auto;
	  bottom: -22px;
	  left: 0;
	  right: 0;
	  width: auto;
	  height: 2px;
	}
  }
  
  @media (min-width: 992px) {
	.main-header-menu .nav-item.show::before {
	  display: block;
	}
  }
  
  .main-header-menu-header {
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
	border-bottom: 1px solid rgba(28, 39, 60, 0.05);
  
	.btn.close {
	  font-weight: 300;
	  font-size: 28px;
	}
  }
  
  @media (min-width: 992px) {
	.main-header-menu-header {
	  display: none;
	}
  }
  
  .main-header-center {
	flex: 1;
	margin: 0 50px;
	position: relative;
	display: none;
  }
  
  @media (min-width: 992px) {
	.main-header-center {
	  display: block;
	}
  }
  
  .main-header-right {
	display: flex;
	align-items: center;
  
	.btn-social {
	  padding: 0;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  color: $gray-500;
	  border-radius: 3px;
	  transition: all 0.2s ease-in-out;
  
	  &:hover, &:focus {
		color: $gray-700;
	  }
  
	  i {
		font-size: 18px;
		line-height: 0;
	  }
  
	  + .btn-social {
		margin-left: 10px;
	  }
	}
  
	.btn-buy {
	  text-transform: uppercase;
	  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	  font-size: 11px;
	  font-weight: 500;
	  background-color: $primary;
	  color: $white;
	  letter-spacing: .5px;
	  display: flex;
	  align-items: center;
	  border-radius: 3px;
	  transition: all 0.2s ease-in-out;
	  margin-left: 20px;
  
	  &:hover, &:focus {
		background-color: #452efa;
	  }
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.main-header-right .btn-social {
	  transition: none;
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.main-header-right .btn-buy {
	  transition: none;
	}
  }
  
  .main-header-search-link {
	font-size: 18px;
	color: $gray-700;
	line-height: 1;
	margin-right: 15px;
  }
  
  .main-header-arrow {
	font-size: 27px;
	color: $gray-900;
	display: inline-block;
	line-height: 0;
  
	i {
	  line-height: 0;
	}
  
	&:hover, &:focus {
	  color: $white;
	}
  }
  
  .main-header-message, .main-header-notification {
	position: relative;
	margin: auto 0;
  }
  
  .profile-name {
	margin-top: 3px;
  }
  
  .main-header-notification {
	margin: auto 0;
  
	&.right-toggle > a::after {
	  border: 2px solid transparent;
	  border-top-color: transparent;
	  border-left-color: transparent;
	  background-color: transparent;
	}
  }
  
  .main-profile-menu > .profile-name::after {
	content: '';
	position: absolute;
	top: 45px;
	left: 50%;
	margin-left: -7px;
	width: 14px;
	height: 14px;
	border: 2px solid transparent;
	border-top-color: #8a78fa;
	border-left-color: #8a77fa;
	transform: rotate(45deg);
	background-color: #8c79fc;
	z-index: 13;
	display: none;
  }
  
  @media (min-width: 992px) {
	.main-header-notification > a::after, .main-header-message > a::after {
	  bottom: -36px;
	}
  }
  
  .main-header-notification .dropdown-menu {
	background-color: $white;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 0;
	border-width: 0;
	margin-top: 0;
	z-index: 900;
	border-radius: 0;
	width: 300px;
	position: absolute;
	top: 52px;
	left: auto;
	right: -10px;
	bottom: auto;
	padding: 0;
	border: $gray-300;
	border-width: 1px;
	z-index: 9;
  }
  
  .main-header-message .dropdown-menu {
	width: 300px;
	position: absolute;
	top: 52px;
	left: auto;
	right: -10px;
	bottom: auto;
	border-color: #dde5ef;
	border-width: 0;
	z-index: 9999;
  }
  
  .main-header-notification {
	.dropdown-footer {
	  text-align: center;
	  padding-top: 10px;
	  font-size: 13px;
	  border-top: 1px dotted $gray-400;
	}
  
	&.show .dropdown-menu {
	  display: block;
	}
  }
  
  @media (min-width: 576px) {
	.main-header-notification.show > a::after, .main-header-message.show > a::after, .drop-flag.show > a::after {
	  display: block;
	}
  }
  
  .main-header-message.show .dropdown-menu, .drop-flag.show .dropdown-menu {
	display: block;
  }
  
  .main-header-profile {
	display: flex;
	flex-direction: column;
	align-items: center;
  
	.main-img-user {
	  width: 80px;
	  height: 80px;
	  margin-bottom: 10px;
  
	  &::after {
		display: none;
	  }
	}
  
	h6 {
	  font-size: 24px;
	  font-weight: 700;
	  color: $gray-900;
	  margin-bottom: 0;
	  font-size: 18px;
	  color: $white;
	}
  
	span {
	  display: block;
	  font-size: 13px;
	  margin-bottom: 0;
	  color: $white;
	}
  }
  
  @media (min-width: 576px) {
	.main-header-profile .main-img-user {
	  width: 80px;
	  height: 80px;
	}
  }
  
  .main-header-sticky {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;
  }
  
	

  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  