
@import "../_variables";
/* ###### 3.6 Forms ###### */

.form-control {	
	&:focus {
	  border-color: $gray-500;
	  box-shadow: none;
	}
  }
  
  .custom-file, .custom-file-input {
	height: 38px;
  }
  
  .custom-file-label {
	height: 38px;
	line-height: 1.8;
	border-radius: 3px;
  
	&::after {
	  line-height: 1.8;
	  border-radius: 0;
	  height: auto;
	}
  }
  
  .form-label {
	display: block;
	margin-bottom: 5px;
	color: $gray-700;
  }
  
  .form-group-rdiobox {
	display: flex;
	align-items: center;
  
	.rdiobox {
	  margin-bottom: 0;
  
	  + .rdiobox {
		margin-left: 30px;
	  }
  
	  span {
		padding-left: 0;
	  }
	}
  }
  
  .formgroup-wrapper {
	.form-control {
	  height: 40px !important;
	}
  
	.main-form-group .form-label {
	  margin-bottom: 12px;
	}
  }
  .form-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-control-label-md {
    padding-left: 8px;
    padding-top: 6px;
}
.custom-control-label-md::before, .custom-control-label-md::after {
    top: 0.25rem !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
}
.custom-control-label-lg {
    padding-left: 15px;
    padding-top: 10px;
}
.custom-control-label-lg::before, .custom-control-label-lg::after {
    top: 0.25rem !important;
    width: 2rem !important;
    height: 2rem !important;
}
.form-switch-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.form-switch-indicator {
    display: inline-block;
    height: 1.25rem;
    width: 2.25rem;
    background: #ecf0fa;
    border-radius: 50px;
    position: relative;
    vertical-align: bottom;
    transition: 0.3s border-color, 0.3s background-color;
}
.form-switch-indicator:before {
    content: "";
    position: absolute;
    height: calc(1.25rem - 4px);
    width: calc(1.25rem - 4px);
    top: 2px;
    left: 2px;
    background: #fff;
    border-radius: 50%;
    transition: 0.3s left;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 40%);
}
.form-switch-input:checked~.form-switch-indicator {
    background: $primary;
}
.form-switch-input:checked~.form-switch-indicator:before {
    left: calc(1rem + 1px);
}
.form-switch-indicator-lg {
    display: inline-block;
    height: 1.5rem;
    width: 3rem;
    background: #ecf0fa;
    border-radius: 50px;
    position: relative;
    vertical-align: bottom;
    border: 1px solid #eae8f1;
    transition: 0.3s border-color, 0.3s background-color;
}
.form-switch-indicator-lg:before {
    content: "";
    position: absolute;
    height: calc(1.5rem - 4px);
    width: calc(1.5rem - 4px);
    top: 1px;
    left: 1px;
    background: #fff;
    border-radius: 50%;
    transition: 0.3s left;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 40%);
}
.form-switch-input:checked~.form-switch-indicator-lg:before {
    left: calc(1.46rem + 1px);
}
.form-switch-indicator-xl {
    display: inline-block;
    height: 2rem;
    width: 4rem;
    background: #ecf0fa;
    border-radius: 50px;
    position: relative;
    vertical-align: bottom;
    border: 1px solid #eae8f1;
    transition: 0.3s border-color, 0.3s background-color;
}
.form-switch-indicator-xl:before {
    content: "";
    position: absolute;
    height: calc(2rem - 4px);
    width: calc(2rem - 4px);
    top: 1px;
    left: 1px;
    background: #fff;
    border-radius: 50%;
    transition: 0.3s left;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 40%);
}
.form-switch-input:checked~.form-switch-indicator {
    background: $primary;
}
.form-switch-input:checked~.form-switch-indicator-xl:before {
    left: calc(1.96rem + 1px) !important;
}
.select2-lg .select2-container .select2-selection--single {
    height: calc(2.6975rem + 2px) !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 100%;
}
.select2-lg .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 28px !important;
}
.select2-lg .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 2.95rem !important;
}
.select2-sm .select2-container .select2-selection--single {
    height: calc(1.5rem + 4px) !important;
    padding-top: 0.391rem;
    padding-bottom: 0.391rem;
    font-size: 0.75rem;
}
.select2-sm .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 13px !important;
}
.select2-sm .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 1.85rem !important;
}